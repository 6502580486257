import styled from 'styled-components';
import { viewMobile, viewSd } from '../../actions/variables';

const FaqStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    .contentWidth{
        header{
            text-align: center;
            margin: 0px;
            padding:  74px 0px;
            font-weight: 700;
            font-size: 34px;
            letter-spacing: 2px;
        }
        .faqs{
            .faq{
                cursor: pointer;
                transition: all 0.3s;
                margin-top: 15px;
                h4{
                    margin: 0px 0px 0px 0px;
                    transition: all 0.3s;
                    padding: 32px;
                    background-color: #f2f0f1;
                    font-size: 25px;
                    color: black;
                }
                .answer{
                    overflow: hidden;
                    transition: all 0.3s;
                    max-height: 0px;
                    color: black;
                }
                &.selected{
                    h4{
                        background-color: #9eb60a;
                        color: white;
                    }
                    .answer{
                        max-height: none;
                        background-color: #a9c028;
                        padding: 32px;
                        color: white;
                    }
                }
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .contentWidth{
            header{
                padding: 50px 0px;
            }
    }
    @media (max-width: ${viewMobile}px){
        .contentWidth{
            header{
                padding: 35px 0px;
            }
            .faqs{
                .faq{
                    h4{
                        padding: 25px;
                        font-size: 22px;
                    }
                    &.selected{
                        .answer{
                            padding: 25px;
                        }
                    }
                }
            }
        }
    }
`;

export default FaqStyle;