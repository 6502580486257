import React, { useEffect } from 'react';
import Step4Style from './Step4Style';
import Switch from "react-switch";
import { urlRodo } from '../../../actions/api';

const Step4 = ({ agreeReg, setAgreeReg, agreeProc, setAgreeProc, agreeRodo, setAgreeRodo , check, setCheckStep , setErrComm , send }) => {

    useEffect(() => {
        if (check){
            setCheckStep(null);
            
            if (!(!agreeReg || !agreeProc || !agreeRodo)) {
                send();
            } else {
                setErrComm('Aby wysłać zgłoszenie wymagamy abyś wyraził wszystkie zgody');
            }
        }
    },[check]);

    return (
        <Step4Style>
            <div className="agreeLayer">
                <div className="agree">
                    <Switch checked={agreeReg} onChange={v => setAgreeReg(v)} />
                </div>
                <div className="text">
                    Sprzedawca oświadcza, że jest prawowitym właścicielem prawnym sprzedawanych produktów do firmy TRIO COLOR. Przedmioty nie pochodzą z popełnienia jakiegokolwiek czynu zabronionego na terenie UE oraz nie są obciążone prawami osób trzecich. Wysyłając formularz sprzedawca potwierdza poprawność swoich danych osobowych.
                </div>
            </div>
            <div className="agreeLayer">
                <div className="agree">
                    <Switch checked={agreeProc} onChange={v => setAgreeProc(v)} />
                </div>
                <div className="text">
                    Wyrażam zgodę na przetwarzanie moich danych osobowych zamieszczonych w niniejszym formularzu przez TRIO COLOR z siedzibą w Warszawie przy ulicy ul. Pola Karolińskie 4 lok. 2, 02-401 Warszawa zgodnie z ustawą z dnia 29.08.1997 r. o ochronie danych osobowych (Dz. U. z 2002 r. nr 101, poz. 926, z późn. zm.) na potrzeby tylko i wyłącznie przeprowadzenia transakcji. Podanie danych ma charakter dobrowolny. Przyjmuję do wiadomości, że przysługuje mi prawo dostępu do moich danych, prawo do ich poprawiania, jak również prawo do odwołania zgody na przetwarzanie danych i żądania ich usunięcia w każdej chwili.
                </div>
            </div>
            <div className="agreeLayer">
                <div className="agree">
                    <Switch checked={agreeRodo} onChange={v => setAgreeRodo(v)} />
                </div>
                <div className="text">
                    W związku z rozpoczęciem stosowania z dniem 25 maja 2018 r. Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE ... 
                    <a href={urlRodo} target="_blank">więcej</a>
                </div>
            </div>
        </Step4Style>
    );
}
export default Step4;