import React , { useState, useEffect } from 'react';

import { FaTimes , FaRegEnvelope, FaLock } from 'react-icons/fa';
import { useClickOutside } from 'react-click-outside-hook';
import { reactLocalStorage } from 'reactjs-localstorage';
import InputMask from 'react-input-mask';
import useMedia from 'use-media';

import FormUtylizeStyle from './FormUtylizeStyle';
import api, { urlPhotos } from '../../actions/api';
import { isEmpty, validateEmail } from '../../actions/functions';
import { firsLetter } from '../../actions/functions';
import { countrys , defaultCountry, viewSd } from '../../actions/variables';
import Upload from '../Upload/Upload';

let saveDataStorage = null;
const maxPhotos = 6;
const isBrowser = typeof window !== "undefined"
const lang = isBrowser ? (navigator.language || navigator.userLanguage) : '';

const Form = ({ setShowForm , showForm , Notify , userData , userToken, setDataComment }) => {

    const [name,setName] = useState('');
    const [surname,setSurname] = useState('');
    const [phone,setPhone] = useState('048');
    const [email,setEmail] = useState('');
    const [country,setCountry] = useState('');
    const [nip,setNip] = useState('');
    const [firm,setFirm] = useState('');
    const [bdo,setBdo] = useState('');
    const [weight,setWeight] = useState('');
    const [content,setContent] = useState('');
    const [photos,setPhotos] = useState([]);
    const [mask,setMask] = useState('+99-999 999 999');

    const [refOverlay, hasClickedOutsideOverlay] = useClickOutside();
    const [errComm,setErrComm] = useState('');
    const [sending,setSending] = useState(false);
    const [allowSend,setAllowSend] = useState(false);

    const [errName,setErrName] = useState(false);
    const [errSurname,setErrSurname] = useState(false);
    const [errPhone,setErrPhone] = useState(false);
    const [errEmail,setErrEmail] = useState(false);
    const [errNip,setErrNip] = useState(false);

    const [loadingPhotos,setLoadingPhotos] = useState(false);
    const [editUserData,setEditUserData] = useState(false);
    const [errorsProfile,setErrorsProfile] = useState([]);
    const [blockData,setBlockData] = useState(false);
    const isResponsive = useMedia({maxWidth: viewSd+'px'});

    const readDataBrowser = (getAll = true) => {
        const dataStorage = reactLocalStorage.getObject('data_form_'+showForm);
        if (!isEmpty(dataStorage)){
            if (getAll){
                setName(dataStorage.name);
                setSurname(dataStorage.surname);
                setPhone(dataStorage.phone);
                setEmail(dataStorage.email);
                setFirm(dataStorage?.firm ? dataStorage.firm : '');
                setNip(dataStorage?.nip ? dataStorage.nip : '');
                setBdo(dataStorage?.bdo ? dataStorage.bdo : '');
            }
            setWeight(dataStorage.weight);
            setContent(String(dataStorage.content));
            setPhotos(dataStorage?.photos!==undefined ? dataStorage?.photos : [])
        } else if (getAll){
            if (userData !== null){
                setName(userData.name);
                setSurname(userData.surname);
                setEmail(userData.email);
                setPhone(userData.phone);
                setBdo(userData?.bdo ? userData.bdo : '');
                setNip(userData?.nip ? userData.nip : '');
            }
        }

        if (country === ''){
            let found = false;
            countrys.forEach(el => {
                if (el.prefix.includes(lang.toUpperCase())){
                    setCountry(el.name);
                    found = true;
                }
            })
            if (!found){
                setCountry(defaultCountry);
            }
        }
    }

    useEffect(() => {
        if (Boolean(userToken)){
            setSending(true);
            api('getBaseDataUser',userToken,{}, r => {
                if (r.result){
                    setName(r.data.userData.name);
                    setSurname(r.data.userData.surname);
                    setEmail(r.data.userData.email);
                    setPhone(r.data.userData.phone);
                    setBdo(r.data.userData.bdo);
                    setNip(r.data.userData.nip);
                    setFirm(r.data.userData.firm_name);
                    readDataBrowser(false);
                } else {
                    readDataBrowser();
                }
                setSending(false);
            });
        } else {
            readDataBrowser();
        }
    },[]);

    useEffect(() => {
        if (country!=''){
            countrys.forEach(el => {
                if (el.name === country){
                    const lengthPrefix = mask.split('-')[0].length;
                    setPhone(phone.replace(phone.substr(0,lengthPrefix),'+'+el.prefixPhone));
                    setMask(el.mask);
                }
            })
        }
    },[country]);

    useEffect(() => {
        if (hasClickedOutsideOverlay){
            setShowForm(false)
        }
    },[hasClickedOutsideOverlay]);

    useEffect(() => {
        if (errComm!==''){
            setTimeout(() => {
                setErrComm('');
            },3000);
        }
    },[errComm]);

    useEffect(() => {
        setBlockData(Boolean(userToken) && !editUserData);
    },[userToken,editUserData]);


    useEffect(() => {
        if (saveDataStorage) clearTimeout(saveDataStorage);

        saveDataStorage = setTimeout(() => {
            reactLocalStorage.setObject('data_form_'+showForm,{ name,surname,phone,email,nip,weight,content,photos,bdo })
        },3000);

        if (name.length>2 && surname.length>2 && validateEmail(email) &&  phone.indexOf('_') < 0 && nip.length>=10 && photos.length>0){
            setAllowSend(true);
        } else {
            setAllowSend(false);
        }

    },[name,surname,phone,email,nip,weight,content,photos,bdo]);

    useEffect(() => {
        errorsProfile.forEach(el => {
            if (el.indexOf('The name field')>=0) setErrName(true);
            if (el.indexOf('The surname field')>=0) setErrSurname(true);
            if (el.indexOf('The phone field')>=0) setErrPhone(true);
            if (el.indexOf('The email field')>=0) setErrEmail(true);
            if (el.indexOf('The nip field')>=0) setErrNip(true);
        });
    },[errorsProfile]);

    const clearData = () => {
        clearTimeout(saveDataStorage);
        setName('');
        setSurname('');
        setPhone('');
        setContent('');
        setEmail('');
        setBdo('');
        setNip('');
        setFirm('');
        setWeight('');
        setPhotos([]);
        reactLocalStorage.remove('data_form_'+showForm);
    }

    const send = () => {
        // console.log('sending ...');
        setSending(true);

        const sendData = {
            name,
            surname,
            phone,
            email,
            country,
            content,
            nip,
            bdo,
            firm,
            photos,
            weight
        }

        api('saveFormUtylize',userToken,sendData,r => {
            setSending(false);
            if (r.result){
                clearData();
                setShowForm(false);
                setDataComment({ header: 'Formularz utylizacji', buttonClose: true, content: 'Dziękujemy za wypełnienie formularza, został on wysłany i będzie dalej procedowany.' });
            } else {
                Notify('Formularz utylizacji','Błąd wysyłania formularza, proszę sprawdzić poprawność danych w formularzu','error');
            }
        });
    }

    const handleRemovePhoto = (ev,photo) => {
        ev.preventDefault();
        setLoadingPhotos(true);
        api('removePhoto','',{ photo },r => {
            setLoadingPhotos(false);
            setPhotos(photos.filter(ph => ph!==photo));

            const dataStorageString = reactLocalStorage.get('data_form_'+showForm);
            if (dataStorageString){
                const dataStorage = JSON.parse(dataStorageString);
                // console.log(typeof dataStorage,dataStorage);
                if (dataStorage?.photos){
                    const photosStorage =  dataStorage.photos.filter(ph => ph != photo);
                    reactLocalStorage.setObject({...dataStorage,...{ photos: photosStorage }});
                }
            }
        });
    }

    const getMultiFiles = result => {
        const data = [...photos];
        let counter = photos.length;
        result.forEach(photo => {
            counter++;
            if (counter<=maxPhotos) data.push(photo);
        });

        setPhotos(data);
    }

    const saveUserData = () => {
        const sendData = {
            name,
            surname,
            phone,
            email,
            country,
            firm,
            utylize: 1,
            bdo,
            nip
        }

        setSending(true);
        api('saveUserData',userToken,sendData,r => {
            setSending(false);
            if (r.result){
                setEditUserData(false);
                Notify('Aktualizacja danych',r.data.comm);
                setErrorsProfile([]);
            } else {
                Notify('Aktualizacja danych',r.comm,'error');
                if (r.errors){
                    setErrorsProfile(r.errors);
                } else {
                    setErrorsProfile([]);
                }
            }
        });
    }

    return (
        <FormUtylizeStyle>
            
            <div className={"overlay"+(sending ? ' loadingLayer' : '')} ref={refOverlay}>
                <header>
                    {'Formularz utylizacji tonerów'}
                    {errComm !== '' && <div className="errComm">{errComm}</div>}
                </header>

                <div className="steps">
                    <div className="form">
                        <div className="formLeft">
                            <div className={"cell cell-group"}>

                                <div className={"cell grouped"+(errName ? ' error' : '')+(blockData ? ' disabled' : '')}>
                                    <label htmlFor="name">Imię *</label>
                                    <input id="name" disabled={blockData} value={name} onChange={v => setName(firsLetter(v.target.value))} onFocus={() => setErrName(false)} />
                                </div>
                                <div className={"cell grouped"+(errSurname ? ' error' : '')+(blockData ? ' disabled' : '')}>
                                    <label htmlFor="surname">Nazwisko *</label>
                                    <input id="surname" disabled={blockData} value={surname} onChange={v => setSurname(firsLetter(v.target.value))} onFocus={() => setErrSurname(false)} />
                                </div>
                                
                            </div>
                            <div className={"cell cell-group"}>
                                <div className={"cell grouped"} style={{ maxWidth: 160 }}>
                                    <label htmlFor="weight">Orientacyjna waga [kg]</label>
                                    <input id="weight" value={weight} onChange={v => setWeight(v.target.value)} />
                                </div>
                                <div className={"cell grouped phone"+(errPhone ? ' error' : '')+(blockData ? ' disabled' : '')} style={{ marginRight: 10 }}>
                                    <label htmlFor="phone">Telefon *</label>
                                    <InputMask id="phone" disabled={blockData} mask={mask} value={phone} onChange={v => setPhone(v.target.value)} onFocus={() => setErrPhone(false)} />
                                </div>
                                <div className={"cell grouped"+(blockData ? ' disabled' : '')}>
                                    <label>Państwo</label>
                                    <select disabled={blockData} onChange={ev => setCountry(ev.target.value)} value={country}>
                                        {countrys.map(obj => (
                                            <option key={`option_country_${obj.prefixPhone}`} value={obj.name}>
                                                {obj.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className={"cell cell-group"}>
                                <div className={"cell grouped"+(errEmail ? ' error' : '')+(blockData ? ' disabled' : '')}>
                                    <label htmlFor="email">E-mail *</label>
                                    <input id="email" disabled={blockData} value={email} onChange={v => setEmail(v.target.value)} onFocus={() => setErrEmail(false)} />
                                </div>
                                <div className={"cell grouped"+(blockData ? ' disabled' : '')+(errNip ? ' error' : '')} style={{ maxWidth: 150 }}>
                                    <label>Nip*</label>
                                    <input value={nip} disabled={blockData} onChange={v => setNip(v.target.value)} onFocus={() => setErrNip(false)} />
                                </div>
                            </div>
                            <div className={"cell cell-group"+(blockData ? ' disabled' : '')}>
                                <div className={"cell grouped"}>
                                    <label>Nazwa firmy</label>
                                    <input value={firm} disabled={blockData} onChange={v => setFirm(v.target.value)} />
                                </div>
                                <div className={"cell"} style={{ maxWidth: 120 }}>
                                    <label htmlFor="bdoxxx">Nr. BDO</label>
                                    <input id="bdoxxx" disabled={blockData} value={bdo} onChange={v => setBdo(v.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="formRight">
                            <div className={"cell"+(errEmail ? ' error' : '')}>
                                <label htmlFor="content">Dodatkowe informacje</label>
                                <textarea id="content" value={content} onChange={v => setContent(v.target.value)} />
                            </div>
                            <div className={"pictures"+(loadingPhotos ? ' loadingLayer' : '')}>
                                <label>Dodaj zdjęcia tonerów, maks. 6 *</label>
                                <div className="uploadImages">
                                    {photos.length>0 &&
                                        <div className="photos">
                                            {photos.map((img,index) => (
                                                <div className="photo" key={`photo_${index}`}>
                                                    <a href="" className="remove" onClick={ev => handleRemovePhoto(ev,img)} title="Usuń zdjęcie">
                                                        <FaTimes color="red" />
                                                    </a>
                                                    <img src={urlPhotos+'small/'+img} />
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {photos.length<maxPhotos &&
                                        <Upload
                                            url="addPhoto"
                                            maxFiles={6}
                                            beforeAction={() => setLoadingPhotos(true)}
                                            afterAction={() => setLoadingPhotos(false)}
                                            successAction={result => getMultiFiles(result)}
                                            Notify={Notify}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"cell last"}>
                        <br />
                        <label htmlFor="content">* Pola obowiązkowe</label>
                        
                    </div>
                    
                </div>

                <div className="buttons">
                    {!editUserData && <button className={"final"+(!allowSend ? ' disabled' : '')} disabled={!allowSend} onClick={() => send()}>Wyślij <FaRegEnvelope /> </button>}
                    {!(isResponsive && editUserData) && <button className="close" onClick={() => setShowForm(false)}><FaTimes /> Zamknij</button>}
                    {(Boolean(userToken)) &&
                        (!editUserData ? 
                            <button className="unlock" onClick={() => setEditUserData(true)}><FaLock /><span> Odblokuj edycję danych</span></button>
                            :
                            <>
                                <button className="editCancel" onClick={() => setEditUserData(false)}><FaTimes /> Anuluj</button>
                                <button className="saveProfile" onClick={() => saveUserData()}><FaTimes /> Zapisz zmiany</button>
                            </>
                        )
                    }
                </div>
            </div>
        </FormUtylizeStyle>
    );
}
export default Form;