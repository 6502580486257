import styled from 'styled-components';
import { viewMobile, viewSd } from '../../../actions/variables';

const Step2Style = styled.div`
    position: relative;
    .categoryImage{
        position: absolute;
        top: 20px;
        left: 56px;
        z-index: 56;
        width: 300px;
        height: 200px;
        background-color: white;
        padding: 20px;
        border: 1px solid grey;
        box-shadow: 0px 0px 50px rgba(0,0,0,0.8);
        img{
            max-width: 100%;
            max-height: 100%;
        }
        .title{
            position: absolute;
            background-color: black;
            color: white;
            padding: 5px 10px;
            position: absolute;
            top: -15px;
            left: 10px;
        }
    }
    .typeOfToners{
        position: relative;
        top: -10px;
        display: flex;
        flex-direction: row;
        
    }
    table{
        width: 100%;
        padding: 0px;
        border-collapse: collapse;
        thead{
            tr{
                background-color: var(--color-base-dark);
                border: 1px solid var(--color-base-dark);
                td{
                    color: white;
                    font-weight: 300;
                }
            }
        }
        tbody{
            tr{
                background-color: #f3f3f3;
                td{
                    border: 1px solid lightgray;
                    label{
                        display: none;
                    }
                    select , input{
                        display: block;
                        width: 100%;
                        border-radius: 0px;
                        outline: 0px;
                        padding: 10px 10px;
                        background-color: white;
                        border: 1px solid lightgray;
                        font-size: 14px;
                        color: grey;
                        background-color: #f9f9f9;
                        transition: all 0.3s;
                        &:focus{
                            border-color: grey;
                            background-color: white;
                        }
                    }
                    button{
                        position: relative;
                        border-radius: 0px;
                        background-color: #f9f9f9;
                        border: 1px solid lightgray;
                        outline: 0px;
                        padding: 8px;
                        color: grey;
                        cursor: pointer;
                        transition: 0.3s;
                        font-size: 13px;
                        display: block;
                        width: 100%;
                        flex: 1;
                        svg{
                            position: relative;
                            top: 3px;
                        }
                        &:hover{
                            background-color: var(--color-base-dark);
                            color: white;
                            border-color: black;
                        }
                        &.remove , &.removePhoto{
                            width: 40px;
                            max-width: 40px;
                            min-width: 40px;
                            margin-left: 4px;
                            background-color: black;
                            border-color: black;
                            color: white;
                            &.removePhoto{
                                display: inline-block;
                                position: relative;
                                top: -6px;
                            }
                            &:hover{
                                background-color: red;
                            }
                        }
                    }
                    .tdButtons{
                        display: flex;
                        flex-direction: row;
                    }
                    .photos{
                        display: flex;
                        flex-direction: row;
                        .dropZone button{
                            padding-left: 5px;
                            padding-right: 5px;
                            width: auto;
                        }
                    }
                    .photo{
                        position: relative;
                        a{
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            display: block;
                            z-index: 10;
                            color: red;
                        }
                    }
                    .dropZone{
                        flex: 1;
                    }
                    .image{
                        position: relative;
                        top: 5px;
                        max-height: 35px;
                    }
                    span.cat{
                        display: inline-block;
                        margin-left: 5px;
                        font-weight: 500;
                        color: green;
                    }
                    &.error{
                        select,input{
                            border: 1px solid #fda4a4;
                            background-color: #ffe1e1;
                        }
                        svg path{
                            fill: red;
                        }
                    }
                }
                &:nth-child(even){
                    background-color: white;
                }
            }
        }
        tr{
            td{
                padding: 4px 6px;
                &.quantity{
                    width: 90px;
                }
            }
        }
    }
    button.add{
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 30px;
        position: relative;
        border-radius: 0px;
        background-color: var(--color-lime-dark);
        border: 1px solid lightgray;
        outline: 0px;
        padding: 8px;
        color: white;
        cursor: pointer;
        transition: 0.3s;
        svg{
            position: relative;
            top: 2px;
        }
        &:hover{
            background-color: var(--color-base-dark);
            border-color: black;
        }
    }
    @media (max-width: ${viewMobile}px){
        .typeOfToners{
            top: 10px;
            margin-bottom: 15px;
        }
    }
    @media (max-width: ${viewSd}px){
        table{
            display: block;
            width: auto !important;
            tbody{
                display: flex;
                flex-direction: column;
                tr{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    border: 0px;
                    background: none;
                    td{
                        display: flex;
                        border: 0px;
                        padding: 5px 0px;
                        label{
                            width: 130px;
                            display: block;
                            position: relative;
                            top: 10px;
                            color: grey;
                            font-size: 12px;
                            &.cat{
                                font-size: 14px;
                            }
                            span{
                                font-size: inherit;
                            }
                        }
                    }
                    &:nth-child(even){
                        background: none;
                    }
                    &:before{
                        width: 100%;
                        display: block;
                        height: 1px;
                        margin: 0px 0px 20px 0px;
                        background-color: grey;
                        content: '';
                    }
                    &:first-child:before{
                        display: none;
                    }
                }
            }
        }
    }
`;	

export default Step2Style;