import React , { useState, useEffect, useContext } from 'react';
import StarsStyle from './StarsStyle';
import { TiStarOutline as StarDefault, TiStarFullOutline as StarFill } from 'react-icons/ti';

const maxStars = 4; 

const Stars = ({ stars , action , setCatName, clearError }) => {

    const [overStar,setOverStar] = useState(0);
    
    useEffect(() => {
        setOverStar(stars);
    },[]);

    const setName = nr => {
        switch (nr){
            case 1 : return 'D';
            case 2 : return 'C';
            case 3 : return 'B';
            case 4 : return 'A';
            default : return '';
        }
    }

    return (
        <StarsStyle onMouseOut={() => {
            setCatName('');
            return stars>0 ? setOverStar(stars) : setOverStar(0)
            }}
        >
            <ul>
                {[1,2,3,4].map(el => (
                    <li 
                        key={`li_${el}`} 
                        className={(overStar>=el ? 'fill' : '')+((stars>=el) ? ' fillStay' : '')} 
                        onMouseOver={v => {
                            setCatName(setName(el))
                            setOverStar(el)
                        }}
                        onClick={() => {
                            action(stars ? 0 : el)
                            clearError();
                        }}
                    >

                            {(overStar>=el || stars>=el) ? <StarFill /> : <StarDefault />}
                    
                    </li>
                ))}
            </ul>
        </StarsStyle>
    );
}
export default Stars;