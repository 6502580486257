import React, { useState, useEffect } from 'react';

import { FaPhoneAlt , FaFacebookMessenger, FaBars , FaUser as IcoUser, FaAt, FaWhatsapp as Whatsup, FaPhone } from 'react-icons/fa';
import { useClickOutside } from 'react-click-outside-hook';
import useMedia from 'use-media';

import HeaderStyle from './HeaderStyle';
import logo from '../../assets/images/sprzedaj-toner-logo.png';
import { phone, viewSd } from '../../actions/variables';
import User from './User/User';

const Header = ({ dataMenu , setMm , setShowForm , userData , setShowLogin , userLogout , slideTo , refTop, setShowForget, userToken, setTkn }) => {

    const isResponsive = useMedia({maxWidth: viewSd+'px'});
    const [showMenuContacts,setShowMenuContacts] = useState(false);
    const [refMenuContacts, hasClickedOutsideMenuContacts] = useClickOutside();

    useEffect(() => {
        if (hasClickedOutsideMenuContacts){
            setShowMenuContacts(false)
        }
    },[hasClickedOutsideMenuContacts]);

    const handleClickHamburger = event => {
        event.preventDefault();
        setMm(true);
    }

    const handleClickMenu = (event,obj) => {
        event.preventDefault();
        if (obj.slide !== undefined){
            slideTo(obj.slide);
        } else if (obj.express!==undefined) {
            setShowForm(1);
        }
    }

    const logout = event => {
        event.preventDefault();
        userLogout();
    }

    const handleLogoClick = event => {
        event.preventDefault();
        slideTo('top');
    }

    const handlePhoneClick = event => {
        if (isResponsive){
            event.preventDefault();
            setShowMenuContacts(true);
        }
    }

    const ResponsiveIcons = () => {
        return (
            <>
                <FaAt /> <Whatsup />
            </>
        )
    }

    return (
        <HeaderStyle className="headerMain">
            <div className="top" ref={refTop}>
                <div className="contentWidth">
                    <h1>Centrum skupu tonerów</h1>
                    <div className="right">
                        <ul className="main">
                            <li className="phone"><a href="tel:602594898" onClick={handlePhoneClick}>{isResponsive && <ResponsiveIcons />}<FaPhoneAlt className="icon" /> {phone}</a></li>
                            <li className="mail"><a href="mailto:info@sprzedajtoner.pl">info@sprzedajtoner.pl</a></li>
                            {/* <li className="socials">
                                <a href="#"><FaFacebook /><span>Facebook</span></a>
                                <a href="#"><FaTwitter /><span>Twitter</span></a>
                                <a href="#"><FaGooglePlusG className="google" /><span>Google</span></a>
                            </li> */}
                        </ul>
                        <div className="userPlace">
                            {userData === null &&
                                <a href="" className="login" title="Zaloguj się" onClick={ev => {
                                    ev.preventDefault();
                                    setShowLogin(true);
                                }}><span>Zaloguj się</span> <IcoUser /></a>
                            }
                            {userData !== null && <User userData={userData} logout={logout} setShowForget={setShowForget} userToken={userToken} setTkn={setTkn} />}
                        </div>
                    </div>

                </div>
            </div>
            <div className="bottom">
                <div className="contentWidth">
                    <a className="logo" href="#" onClick={handleLogoClick}><img src={logo} alt="Sprzedaj toner" /></a>
                    <a href="#" className="hamburgerMenu" onClick={handleClickHamburger}><FaBars /></a>
                    <ul>
                        {dataMenu.map((obj,index) => (
                            <li key={`menu_${index}`}>
                                <a href="#" className={obj.className!==undefined ? obj.className : null} onClick={(event) => handleClickMenu(event,obj)}>{obj.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className={"mobileContacts"+(showMenuContacts ? ' show' : '')} ref={refMenuContacts}>
                <ul>
                    <li className="phone"><a href="tel:602594898"><FaPhone /> zadzwoń</a></li>
                    <li className="email"><a href="mailto:info@sprzedajtoner.pl"><FaAt /> wyślij e-mail</a></li>
                    <li className="whatsup"><a href="https://wa.me/48602594898"><Whatsup /> wiadomość na Whatsup</a></li>
                    <li className="messanger"><a href="http://m.me/910981668954211/"><FaFacebookMessenger /> wiadomość na Messengerze</a></li>
                </ul>
            </div>
            <div className={"mobileContactsMask"+(showMenuContacts ? ' show' : '')} onClick={() => setShowMenuContacts(false)}></div>
            
        </HeaderStyle>
    );
}
export default Header;