import React, { useEffect } from 'react';
import Switch from "react-switch";
import Step3Style from './Step3Style';

const Step3 = ({ delivery, setDelivery, bank, setBank, content , setContent , check , goStep , addrUps , setAddrUps }) => {

    useEffect(() => {
        if (check){
            goStep(4);
        }
    },[check]);

    return (
        <Step3Style className={(delivery === 1) ? 'addColumn' : ''}>
            <div className="layerSwitch">
                <h3 className="first">Sposób dostawy</h3>
                <div className="deliveryLayer">
                    <div className="delivery">
                        <Switch 
                            onChange={v => setDelivery(0)} 
                            checked={delivery === 0} 
                            uncheckedIcon={false}
                        />
                        <div className="label">Osobiście</div>
                    </div>
                    <div className="delivery">
                        <Switch 
                            onChange={v => setDelivery(1)}
                            checked={delivery === 1}
                            uncheckedIcon={false}
                        />
                        <div className="label">Kurier UPS</div>
                    </div>
                    <div className="delivery">
                        <Switch 
                            onChange={v => setDelivery(2)}
                            checked={delivery === 2}
                            uncheckedIcon={false}
                        />
                        <div className="label">Kurier UPS (sam nadam paczkę u przewoźnika)</div>
                    </div>
                    
                </div>
            </div>
            {(delivery === 1) &&
                <div className="layerSwitch">
                    <h3>Adres podjęcia paczki (jeśli jest inny niż dane w formularzu)</h3>
                    <div className="contentAdressUps">
                        <textarea value={addrUps} onChange={ev => setAddrUps(ev.target.value)} />
                    </div>
                </div>
            }
            <div className="layerSwitch layerSwitchContent">
                <h3>Dodatkowy opis/informacja dla odbiorcy</h3>
                <textarea value={content} onChange={ev => setContent(ev.target.value)} />
            </div>
        </Step3Style>
    );
}
export default Step3;