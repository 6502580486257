import React from 'react';
import LogosStyle from './LogosStyle';
import Logo1 from '../../assets/images/sprzedaj-toner-www-full-page-02_40.jpg';
import Logo2 from '../../assets/images/sprzedaj-toner-www-full-page-02_42.jpg';
import Logo3 from '../../assets/images/sprzedaj-toner-www-full-page-02_44.jpg';
import Logo4 from '../../assets/images/sprzedaj-toner-www-full-page-02_47.jpg';
import Logo5 from '../../assets/images/sprzedaj-toner-www-full-page-02_50.jpg';
import Logo6 from '../../assets/images/sprzedaj-toner-www-full-page-02_53.jpg';
import Logo7 from '../../assets/images/sprzedaj-toner-www-full-page-02_56.jpg';
import Logo8 from '../../assets/images/sprzedaj-toner-www-full-page-02_59.jpg';
import Logo9 from '../../assets/images/sprzedaj-toner-www-full-page-02_61.jpg';

const Logos = () => {

    const data= [
        { image: Logo1, name: 'Samsung' },
        { image: Logo2, name: 'Brother' },
        { image: Logo3, name: 'Canon' },
        { image: Logo4, name: 'HP' },
        { image: Logo5, name: 'Epson' },
        { image: Logo6, name: 'Ricoh' },
        { image: Logo7, name: 'Lexmark' },
        { image: Logo8, name: 'OKI' },
        { image: Logo9, name: 'Xerox' },
    ]

    return (
        <LogosStyle>
            <div className="contentWidth">
                {data.map((obj,index) => (
                    <div className="firmLogo" key={`logos_${index}`}>
                        <img src={obj.image} alt={obj.name} />
                    </div>
                ))}
            </div>
        </LogosStyle>
    );
}
export default Logos;