import React, { useEfefct,useEffect,useState } from 'react';
import Dropzone from 'react-dropzone';
import { API_FILE } from '../../actions/api';
import { FaPlus } from 'react-icons/fa';

let DR = [0,0];

const Upload = ({ url, setFile , successAction, errorAction , accept , beforeAction , afterAction, addLabel = true, maxFiles=1, Notify }) => {


    const [doneFiles,setDoneFiles] = useState(0);
    const [cPhotos,setCphotos] = useState(0);
    const [resultFiles,setResultFiles] = useState([]);

    useEffect(() => {

        if (cPhotos>0 && doneFiles === cPhotos){
            if (DR[0]){
                if (maxFiles===1){
                    if (setFile!==undefined) setFile(resultFiles[0]);
                    if (successAction!==undefined) successAction(resultFiles[0]);
                } else {
                    if (setFile!==undefined) setFile(resultFiles);
                    if (successAction!==undefined) successAction(resultFiles);
                }
            } else {
                if (maxFiles===1){
                    
                }
            }
            if (afterAction!==undefined) afterAction();

            setDoneFiles(0);
            setCphotos(0);
            setResultFiles([]);
        }

    },[doneFiles,resultFiles]);

    const appendFile = files => {

        if (files.length>maxFiles || files.length === 0) {
            if (maxFiles === 1){
                Notify('Przenoszenie zdjęć','Możesz dodawać tylko po jednym zdjęciu toneru','error');
            }
            return;
        }
        setCphotos(files.length);
        if (beforeAction!==undefined) beforeAction();
        files.forEach(file => {
            API_FILE(url, file, '', r => {
                // if (afterAction!==undefined) afterAction();
                if (r.result){
                    DR[0]++;
                    // .push(r.data.file);
                    setResultFiles(v => [...v,r.data.photo]);
                } else {
                    DR[1]++;
                }
                setDoneFiles(v => v+1);
            });
        })

    }

    return (
        <Dropzone maxFiles={maxFiles} onDrop={acceptedFiles => appendFile(acceptedFiles)} accept={accept===undefined ? '.jpg,.png,.jpeg' : accept}  maxSizeBytes={10485760} multiple={maxFiles>1}>
            {({getRootProps, getInputProps}) => {
                return (
                    <div {...getRootProps()} className="dropZone">
                        <input {...getInputProps()} />
                        <button><FaPlus />{addLabel ? ' Dodaj zdjęcie' : ''}</button>
                    </div>
                )
            }}
        </Dropzone>
    )
}

export default Upload;