import React , { useState, useEffect, useContext } from 'react';

const facebookAppId = "910981668954211"

const Messanger = () => {

    useEffect(()=>{
        window.fbAsyncInit = function() {
          window.FB.init({
            xfbml            : true,
            version          : 'v5.0'
          });
        };
        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    },[])

    return (
        <>
            <div id="fb-root" />
            <div
                className="fb-customerchat"
                attribution="setup_tool"
                page_id={facebookAppId}
            />
        </>
    );
}
export default Messanger;