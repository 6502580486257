import styled from 'styled-components';

const CreateAccountStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 30px;
    .question{
        margin: 30px;
        text-align: center;
        font-size: 25px;
    }
    .questionButtons{
        text-align: center;
        button{
            margin: 0px 30px;
            padding: 30px 50px;
            font-size: 30px;
            text-transform: uppercase;
            outline: 0px;
            color: white;
            border:  1px solid black;
            cursor: pointer;
            &.yes{
                background-color: green;
            }
            &.no{
                background-color: red;
            }
        }
    }
`;	

export default CreateAccountStyle;