import styled from 'styled-components';

const Step4Style = styled.div`
    .agreeLayer{
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        .agree{
            margin-right: 20px;
        }
        .text{
            font-size: 16px;
            color: grey;
            font-weight: 300;
            a{
                color: inherit;
                &:hover{
                    color: black;
                }
            }
        }
    }
`;	

export default Step4Style;