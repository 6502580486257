import React , { useRef, useState , useEffect } from 'react';
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from 'react-icons/fa';
import { validateEmail, firsLetter } from '../../../actions/functions';
import InputMask from 'react-input-mask';
import Step1Style from './Step1Style';
import { countrys , defaultCountry } from '../../../actions/variables';

import "react-datepicker/dist/react-datepicker.css";

const isBrowser = typeof window !== "undefined"
const lang = isBrowser ? (navigator.language || navigator.userLanguage) : '';

const Step1 = (props) => {

    const refCal = useRef();
    const [errName,setErrName] = useState(false);
    const [errSurname,setErrSurname] = useState(false);
    const [errPhone,setErrPhone] = useState(false);
    const [errEmail,setErrEmail] = useState(false);
    const [errStreet,setErrStreet] = useState(false);
    const [errHouse,setErrHouse] = useState(false);
    const [errCity,setErrCity] = useState(false);
    const [errCode,setErrCode] = useState(false);
    const [errFirm,setErrFirm] = useState(false);
    const [errDate,setErrDate] = useState(false);
    const [mask,setMask] = useState('+99-999 999 999');
    const [blockData,setBlockData] = useState(false);

    useEffect(() => {
        if (props.country === ''){
            let found = false;
            countrys.forEach(el => {
                if (el.prefix.includes(lang.toUpperCase())){
                    props.setCountry(el.name);
                    found = true;
                }
            })
            if (!found){
                props.setCountry(defaultCountry);
            }
        }
    },[]);

    useEffect(() => {
        setBlockData(props.blockDataProfile);
    },[props.blockDataProfile]);

    useEffect(() => {
        props.errorsProfile.forEach(el => {
            if (el.indexOf('The name field')>=0) setErrName(true);
            if (el.indexOf('The surname field')>=0) setErrSurname(true);
            if (el.indexOf('The phone field')>=0) setErrPhone(true);
            if (el.indexOf('The email field')>=0) setErrEmail(true);
            if (el.indexOf('The street field')>=0) setErrStreet(true);
            if (el.indexOf('The code field')>=0) setErrCode(true);
            if (el.indexOf('The city field')>=0) setErrCity(true);
        });
    },[props.errorsProfile]);

    useEffect(() => {
        if (props.country!=''){
            countrys.forEach(el => {
                if (el.name === props.country){
                    const lengthPrefix = mask.split('-')[0].length;
                    setMask(el.mask);
                    props.setPhone(props.phone.replace(props.phone.substr(0,lengthPrefix),'+'+el.prefixPhone));
                }
            })
        }
    },[props.country]);

    useEffect(() => {
        if (props.check){
            let err = false;
            if (String(props.name).trim().length<2){
                setErrName(true);
                err = true;
            }
            if (String(props.surname).trim().length<3){
                setErrSurname(true);
                err = true;
            }
            // const tempPhone = props.phone.replaceAll("_","");
            if (props.phone.indexOf('_') >= 0){
                setErrPhone(true);
                err = true;
            }
            if (!validateEmail(String(props.email).trim())){
                setErrEmail(true);
                err = true;
            }
            if (String(props.city).trim().length<2){
                setErrCity(true);
                err = true;
            }
            if (String(props.code).trim().length<5){
                setErrCode(true);
                err = true;
            }
            if (String(props.street).trim().length<3){
                setErrStreet(true);
                err = true;
            }
            if (String(props.house).trim().length<1){
                setErrHouse(true);
                err = true;
            }

            props.setCheckStep(null);
            if (!err) props.goStep(2);
        }
    },[props.check]);

    return (
        <Step1Style className="body">
            <div className={"cell"+(errName ? ' error' : '')+(blockData ? ' disabled' : '')}>
                <label htmlFor="name">Imię</label>
                <input id="name" disabled={blockData} value={props.name} onChange={v => props.setName(firsLetter(v.target.value))} onFocus={() => setErrName(false)} />
            </div>
            <div className={"cell"+(errSurname ? ' error' : '')+(blockData ? ' disabled' : '')}>
                <label htmlFor="surname">Nazwisko</label>
                <input id="surname" disabled={blockData} value={props.surname} onChange={v => props.setSurname(firsLetter(v.target.value))} onFocus={() => setErrSurname(false)} />
            </div>
            <div className={"cell"}>
                <label htmlFor="country">Państwo</label>
                <select disabled={blockData} onChange={ev => props.setCountry(ev.target.value)} value={props.country}>
                    {countrys.map(obj => (
                        <option key={`option_country_${obj.prefixPhone}`} value={obj.name}>
                            {obj.name}
                        </option>
                    ))}
                </select>
                {/* <input id="country" value={props.country} onChange={v => props.setCountry(v.target.value)} onFocus={() => setErrCountry(false)} /> */}
            </div>
            <div className={"cell"+(errPhone ? ' error' : '')+(blockData ? ' disabled' : '')}>
                <label htmlFor="phone">Telefon</label>
                <InputMask disabled={blockData} mask={mask} value={props.phone} onChange={v => props.setPhone(v.target.value)} onFocus={() => setErrPhone(false)} />
            </div>
            <div className={"cell"+(errEmail ? ' error' : '')+(blockData ? ' disabled' : '')}>
                <label htmlFor="email">E-mail</label>
                <input disabled={blockData} id="email" value={props.email} onChange={v => props.setEmail(v.target.value)} onFocus={() => setErrEmail(false)} />
            </div>
            <div className={"cell cell-group"+(errStreet ? ' error' : '')+(blockData ? ' disabled' : '')}>
                <div className="cell grouped street">
                    <label htmlFor="street">Ulica</label>
                    <input disabled={blockData} id="street" value={props.street} onChange={v => props.setStreet(firsLetter(v.target.value))} onFocus={() => setErrStreet(false)} />
                </div>
                <div className={"cell grouped home"+(errHouse ? ' error' : '')+(blockData ? ' disabled' : '')}>
                    <label htmlFor="house">Nr domu / Mieszkania</label>
                    <InputMask disabled={blockData} id="house" mask="***/9999" value={props.house} onChange={v => props.setHouse(v.target.value)} onFocus={() => setErrHouse(false)} />
                </div>
            </div>
            <div className={"cell cell-group"}>
                <div className={"cell grouped postcode"+(errCode ? ' error' : '')+(blockData ? ' disabled' : '')}>
                    <label htmlFor="code">Kod pocztowy</label>
                    <input disabled={blockData} id="code" value={props.code} onChange={v => props.setCode(v.target.value)} onFocus={() => setErrCode(false)} />
                    {/* <InputMask id="code" value={props.code} onChange={v => props.setCode(v.target.value)} onFocus={() => setErrCode(false)} mask="99-999" maskChar="_" /> */}
                </div>
                <div className={"cell grouped"+(errCity ? ' error' : '')+(blockData ? ' disabled' : '')}>
                    <label htmlFor="city">Miejscowość</label>
                    <input disabled={blockData} id="city" value={props.city} onChange={v => props.setCity(firsLetter(v.target.value))} onFocus={() => setErrCity(false)} />
                </div>
            </div>
            <div className={"cell"+(errFirm ? ' error' : '')+(blockData ? ' disabled' : '')}>
                <label htmlFor="firmName">Nazwa firmy <span>opcjonalnie</span></label>
                <input disabled={blockData} id="firmName" value={props.firm} onChange={v => props.setFirm(v.target.value)} onFocus={() => setErrFirm(false)} />
            </div>
            <div className={"cell account"+(blockData ? ' disabled' : '')}>
                <label htmlFor="bank">Twój numer konta bankowego <span>opcjonalnie</span></label>
                <input disabled={blockData} id="bank" value={props.bank} onChange={v => props.setBank(v.target.value)} />
            </div>
            <div className={"cell"+(errDate ? ' error' : '')}>
                <label htmlFor="date">Proponowana data odbioru</label>
                <div className="icon">
                    <DatePicker 
                        id="date"
                        dateFormat="yyyy-MM-dd"
                        selected={props.date} 
                        onChange={date => props.setDate(date)} 
                        onCalendarOpen={() => setErrDate(false)}
                        popperPlacement="top-end"
                        ref={refCal}
                        minDate={new Date()}
                    />
                    <FaCalendarAlt onClick={() => refCal.current.deferFocusInput()} />
                </div>
            </div>
        </Step1Style>
    );
}
export default Step1;