import React from 'react';

const Svg = ({ name , color }) => {

    const choose = () => {
        switch (name) {
            case 'arrow-right':
                return (
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 113.61 184.992">
                            <path 
                                fill={color!==undefined ? color: '#ffffff'} 
                                d="M104.314,87.776L18.408,2.183c-2.497-2.488-6.541-2.488-9.038,0 c-2.498,2.489-2.498,6.52,0,9.008L90.749,92.28L9.37,173.365c-2.498,2.489-2.498,6.525,0,9.013c2.497,2.485,6.541,2.485,9.038,0 l85.906-85.593C106.811,94.296,106.811,90.265,104.314,87.776z"
                            />
                    </svg>
                );
            case 'hand':
                return (
                    <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" x="0px"
                            y="0px" width="451.125px" height="511px" viewBox="0 0 451.125 511"
                            >
                        <g>
                            <path fill="#FFFFFF" d="M230.894,338.556c59.94-1.188,106.163-48.42,104.952-107.245
                                c-1.188-57.75-49.173-103.743-106.996-102.556c-58.813,1.207-104.16,49.048-102.939,108.603
                                C127.044,292.669,175.779,339.646,230.894,338.556z"/>
                        </g>
                        <g id="XMLID_1_">
                            <g>
                                <path fill="#FFFFFF" d="M401.437,391.14c10.1-4.72,19.6-3.39,29.189,3.95c-13.979,9.06-27.229,17.64-40.479,26.21
                                    c-31.42,20.34-62.891,40.6-94.261,61.02c-17.76,11.561-37.55,15.36-57.779,11.75c-20.18-3.6-39.86-10.1-59.71-15.489
                                    c-16.66-4.53-33.25-9.311-49.88-13.95c-12.58-3.51-24.68-2.3-36.23,3.89c-2.32,1.24-4.66,2.47-7.31,3.87
                                    c-0.34-0.92-0.73-1.479-0.72-2.02c0.74-26.65,1.44-53.29,2.43-79.92c0.07-1.961,1.84-4.61,3.59-5.631
                                    c29.81-17.34,60.37-17.989,90.88-2.38c15.19,7.78,30.78,11.84,47.82,11.88c18.319,0.04,36.64,0.94,54.939,1.681
                                    c15.83,0.64,30.65,13.8,32.641,28.859c-4,0-7.98,0.091-11.95-0.01c-25.641-0.67-51.29-1.41-76.931-2.04
                                    c-5.819-0.14-9.459,2.96-9.7,7.8c-0.22,4.75,3.29,8.221,8.91,8.74c1.49,0.14,2.99,0.2,4.49,0.25
                                    c13.82,0.41,27.641,0.851,41.46,1.22c16.65,0.431,33.311,0.86,49.96,1.19c9.471,0.19,13.19-3.45,11.67-12.63
                                    c-0.859-5.18,0.75-7.13,5.19-9.16C360.347,410.729,380.817,400.779,401.437,391.14z"/>
                                <path fill="#FFFFFF" d="M20.646,360.59c16.86,0,32.95,0,49.78,0c-1.19,43.52-2.36,86.31-3.53,128.77c-16.81,0-33.04,0-49.8,0
                                    C18.297,445.93,19.467,403.26,20.646,360.59z"/>
                                <path d="M448.886,391.33c3.69,5.949,2.91,10.1-3.029,13.949c-49.16,31.841-98.181,63.87-147.69,95.141
                                    c-7.89,4.99-18.01,6.439-27.1,9.52c-1.07,0.36-2.13,0.71-3.2,1.061c-12,0-24,0-36,0c-24.92-6.98-49.83-13.971-74.75-20.94
                                    c-11.35-3.17-22.71-6.35-34.07-9.47c-7.35-2.021-14.5-1.44-21.33,2.05c-2.96,1.51-5.8,3.28-8.82,4.64
                                    c-5.6,2.521-10.25,5.131-9.78,12.78c0.27,4.391-3.39,7.19-8.06,7.101c-21.99-0.431-43.98-0.86-65.96-1.551
                                    c-6.24-0.199-9.26-3.81-9.09-10.18c1.1-39.8,2.3-79.61,3.44-119.41c0.22-7.49,0.32-14.99,0.48-22.49
                                    c0.18-8.06,3.02-10.89,10.89-10.71c20.32,0.45,40.64,0.87,60.96,1.431c9.56,0.27,11.98,3.069,11.63,12.67
                                    c-0.11,2.99-0.02,5.979-0.02,9.49c4.9-1.9,9.69-3.94,14.6-5.62c28.99-9.88,57.15-7.66,84.79,5.39c8.63,4.09,17.66,7.7,26.89,10.07
                                    c6.81,1.739,14.22,1.27,21.37,1.51c15.15,0.5,30.311,0.86,45.46,1.18c20.2,0.42,35.771,9,46.53,26.16
                                    c0.35,0.55,0.76,1.07,1.38,1.93c10.35-4.88,20.68-9.729,31-14.6c12.02-5.67,23.96-11.53,36.07-17.01
                                    C415.006,366.59,437.777,373.47,448.886,391.33z M430.626,395.09c-9.59-7.34-19.09-8.67-29.189-3.95
                                    c-20.62,9.64-41.09,19.59-61.78,29.08c-4.44,2.03-6.05,3.98-5.19,9.16c1.521,9.18-2.199,12.82-11.67,12.63
                                    c-16.649-0.33-33.31-0.76-49.96-1.19c-13.819-0.369-27.64-0.81-41.46-1.22c-1.5-0.05-3-0.11-4.49-0.25
                                    c-5.62-0.52-9.129-3.99-8.91-8.74c0.24-4.84,3.88-7.939,9.7-7.8c25.641,0.63,51.29,1.37,76.931,2.04
                                    c3.97,0.101,7.95,0.01,11.95,0.01c-1.99-15.06-16.811-28.22-32.641-28.859c-18.3-0.74-36.62-1.641-54.939-1.681
                                    c-17.041-0.04-32.63-4.1-47.82-11.88c-30.51-15.609-61.07-14.96-90.88,2.38c-1.75,1.021-3.52,3.67-3.59,5.631
                                    c-0.99,26.63-1.69,53.27-2.43,79.92c-0.01,0.54,0.38,1.1,0.72,2.02c2.65-1.4,4.99-2.63,7.31-3.87
                                    c11.55-6.189,23.65-7.399,36.23-3.89c16.63,4.64,33.22,9.42,49.88,13.95c19.85,5.39,39.53,11.89,59.71,15.489
                                    c20.229,3.61,40.02-0.189,57.779-11.75c31.37-20.42,62.841-40.68,94.261-61.02C403.397,412.729,416.647,404.149,430.626,395.09z
                                    M70.427,360.59c-16.83,0-32.92,0-49.78,0c-1.18,42.67-2.35,85.34-3.55,128.77c16.76,0,32.99,0,49.8,0
                                    C68.067,446.899,69.237,404.109,70.427,360.59z"/>
                            </g>
                            <g>
                            </g>
                        </g>
                        <g>
                            <path d="M233.318,0c6.089,3.322,5.844,7.825,5.838,12.356
                                c-0.035,24.326,0.027,48.651-0.006,72.978c-0.003,2.134,0.046,4.501-0.813,6.351c-1.7,3.66-4.924,5.385-9.029,4.606
                                c-4.102-0.779-6.507-3.424-6.599-7.6c-0.185-8.493-0.145-16.992-0.158-25.488c-0.027-17.328-0.011-34.655-0.053-51.983
                                c-0.01-4.126,0.408-8.01,5.281-10.875L233.318,0z"/>
                        </g>
                        <g>
                            <path fill="#A1B63A" d="M231.115,355.206c-65.703,0.962-121.087-53.269-122.146-119.601
                                c-1.075-67.389,52.838-122.66,120.63-123.668c66.521-0.99,122.171,52.774,123.086,119.123
                                C353.663,301.9,296.487,355.707,231.115,355.206z M230.894,338.556c59.94-1.188,106.163-48.42,104.952-107.245
                                c-1.188-57.75-49.173-103.743-106.996-102.556c-58.813,1.207-104.16,49.048-102.939,108.603
                                C127.044,292.669,175.779,339.646,230.894,338.556z"/>
                        </g>
                        <g>
                            <path d="M159.625,68.981c0-6.142-0.16-12.29,0.048-18.425
                                c0.181-5.342,3.605-8.625,8.362-8.532c4.754,0.092,8.05,3.472,8.104,8.835c0.122,12.119,0.121,24.24,0.022,36.359
                                c-0.046,5.632-3.345,9.182-8.205,9.307c-4.806,0.124-8.196-3.449-8.322-9.116c-0.136-6.14-0.031-12.285-0.031-18.428
                                C159.611,68.981,159.618,68.981,159.625,68.981z"/>
                        </g>
                        <g>
                            <path d="M302.139,68.982c0.003,6.143,0.097,12.288-0.024,18.428
                                c-0.109,5.546-3.51,9.121-8.381,9.121c-4.637,0-8.042-3.443-8.109-8.818c-0.152-12.118-0.176-24.24-0.029-36.357
                                c0.074-6.067,3.527-9.549,8.609-9.331c4.896,0.209,7.847,3.687,7.916,9.523C302.189,57.359,302.136,63.171,302.139,68.982z"/>
                        </g>
                        <g>
                            <path fill="#A1B526" d="M211.847,205.817c0.109,9.391,6.854,17.207,16.432,18.296
                                c8.666,0.986,17.145,2.034,24.291,7.762c10.439,8.368,15.361,19.275,13.875,32.426c-1.583,13.996-9.33,24.141-22.487,29.298
                                c-4.404,1.727-6.255,3.448-5.972,8.286c0.354,6.056-3.121,9.828-8.063,9.968c-5.076,0.143-8.699-3.749-8.305-10.172
                                c0.305-4.966-1.495-6.991-5.974-9.037c-12.7-5.798-19.291-16.356-20.646-30.153c-0.559-5.692,2.711-9.977,7.601-10.459
                                c5.026-0.496,8.818,3.358,9.264,9.416c0.753,10.238,8.493,17.618,18.588,17.725c10.34,0.109,18.404-6.957,19.38-16.978
                                c0.96-9.856-5.688-18.597-15.737-20.456c-3.904-0.723-7.967-0.708-11.806-1.646c-15.462-3.782-26.193-16.653-27.304-32.374
                                c-1.054-14.926,7.885-29.603,22.076-35.231c3.694-1.465,4.867-3.251,4.396-6.901c-0.208-1.614-0.015-3.358,0.368-4.954
                                c1.051-4.376,4.293-6.402,8.459-6.228c3.999,0.168,6.808,2.542,7.574,6.695c0.27,1.458,0.331,3.008,0.166,4.48
                                c-0.371,3.313,0.836,4.871,4.176,6.026c14.453,5.003,22.237,15.644,24.439,30.548c0.941,6.372-2.497,11.37-7.824,11.754
                                c-4.896,0.354-8.416-3.476-8.967-9.758c-0.903-10.296-9.455-18.166-19.313-17.771C219.82,186.809,211.723,195.23,211.847,205.817z"
                                />
                        </g>
                    </svg>
                );
            case 'money':
                return (
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            width="428.544px" height="428.901px" viewBox="0 0 428.544 428.901"
                            >
                        <g>
                            <path fill="#FFFFFF" d="M21.36,69.331c0,11.37,0.25,22.37-0.12,33.339c-0.15,4.391,1.46,7.28,5.09,8.73
                                c8.9,3.59,17.721,7.82,27,9.92c41.651,9.43,83.562,9.42,125.29,0.721c10.111-2.111,19.75-6.65,29.42-10.511
                                c2.15-0.86,5-3.53,5.07-5.469c0.471-12.21,0.24-24.45,0.24-36.69C149.12,91.78,85.501,91.84,21.36,69.331z"/>
                            <path fill="#FFFFFF" d="M19.53,42.811c4.78,2.969,7.909,5.379,11.399,7.01C47.4,57.53,65.079,60.55,82.95,62.46
                                c22.561,2.42,45.17,2.56,67.75,0.069c17.711-1.949,35.221-4.889,51.66-12.129c3.98-1.75,7.551-4.42,12.74-7.51
                                c-4.869-3.021-8.141-5.561-11.811-7.221c-15.6-7.061-32.24-10.16-49.109-12.109c-24.561-2.83-49.15-2.851-73.711,0.02
                                c-16.689,1.96-33.2,4.89-48.64,11.92C28.03,37.241,24.59,39.78,19.53,42.811z"/>
                            <path fill="#FFFFFF" d="M234.732,102.15c2.33,0.5,4.26,1.04,6.229,1.311c35.391,4.85,70.771,5.08,105.84-2.121
                                c11.111-2.27,21.941-6.459,32.48-10.77c6.451-2.641,6.41-5.969,0.27-9.15c-6.738-3.49-13.83-6.6-21.1-8.71
                                c-28.98-8.39-58.77-9.25-88.629-7.96c-11.701,0.5-23.352,2.32-35.09,3.55C234.732,80.081,234.732,90.881,234.732,102.15z"/>
                            <path fill="#FFFFFF" d="M159.001,147.169c-46.811,4.931-92.971,4.17-137.651-14.56c0,12.229,0.181,23.38-0.1,34.52
                                c-0.09,3.7,1.15,6.271,4.2,7.73c5.66,2.729,11.33,5.63,17.29,7.539c25.319,8.09,51.511,9.66,77.87,9.98
                                c3.701,0.049,5.66-1.17,7.271-4.42c1.898-3.86,4.17-7.64,6.828-11.01C142.521,167.089,150.601,157.439,159.001,147.169z"/>
                            <path fill="#FFFFFF" d="M153.581,382.068c-8.67-12.031-17.391-23.531-25.35-35.531c-2.441-3.67-4.49-5.27-8.891-5.09
                                c-27.99,1.17-55.411-2.309-82.101-10.99c-5.17-1.689-10.29-3.539-15.9-5.479c0,11.5,0.24,22.479-0.109,33.449
                                c-0.15,4.529,1.449,7.551,5.34,9.07c9.08,3.539,18.09,7.609,27.5,9.939c28.69,7.1,57.931,7.84,87.29,5.9
                                C145.62,383.059,149.86,382.457,153.581,382.068z"/>
                            <path fill="#FFFFFF" d="M114.892,213.498c-32.08,1.061-63.111-4.949-93.542-16.67c0,11.76,0.261,22.74-0.119,33.699
                                c-0.16,4.73,1.67,7.541,5.55,9.541c1.62,0.84,3.2,1.77,4.87,2.48c23.8,10.09,48.97,12.479,73.931,13.59
                                C108.7,241.848,111.731,227.988,114.892,213.498z"/>
                            <path fill="#FFFFFF" d="M114.392,319.438c-2.84-13.639-5.75-27.639-8.711-41.83c-28.871-0.02-56.841-5.699-84.32-16.799
                                c0,8.26-0.021,15.869,0,23.479c0.04,18.41,0.579,19.16,18.12,25.23c0.939,0.33,1.869,0.76,2.84,0.93
                                c16.29,3.021,32.528,6.441,48.921,8.801C98.991,320.367,107.03,319.438,114.392,319.438z"/>
                            <path fill="#FFFFFF" d="M384.142,112.509c-14.471,4.25-28.801,8.471-41.58,12.24c-26.07,8.15,27.289,20.83,41.369,31.57
                                c-0.08,0.23,0.189-0.199,0.189-0.641C384.142,141.199,384.142,126.73,384.142,112.509z"/>
                            <g>
                            </g>
                            <g>
                                <path fill="#FFFFFF" d="M21.36,284.287c0.04,18.41,0.579,19.16,18.12,25.23c0.939,0.33,1.869,0.76,2.84,0.93
                                    c16.29,3.021,32.528,6.441,48.921,8.801c7.75,1.119,15.789,0.189,23.15,0.189c-2.84-13.639-5.75-27.639-8.711-41.83
                                    c-28.871-0.02-56.841-5.699-84.32-16.799C21.36,269.068,21.34,276.678,21.36,284.287z"/>
                                <path fill="#FFFFFF" d="M26.78,240.068c1.62,0.84,3.2,1.77,4.87,2.48c23.8,10.09,48.97,12.479,73.931,13.59
                                    c3.119-14.291,6.15-28.15,9.311-42.641c-32.08,1.061-63.111-4.949-93.542-16.67c0,11.76,0.261,22.74-0.119,33.699
                                    C21.07,235.258,22.9,238.068,26.78,240.068z"/>
                                <path fill="#FFFFFF" d="M119.341,341.447c-27.99,1.17-55.411-2.309-82.101-10.99c-5.17-1.689-10.29-3.539-15.9-5.479
                                    c0,11.5,0.24,22.479-0.109,33.449c-0.15,4.529,1.449,7.551,5.34,9.07c9.08,3.539,18.09,7.609,27.5,9.939
                                    c28.69,7.1,57.931,7.84,87.29,5.9c4.26-0.279,8.5-0.881,12.221-1.27c-8.67-12.031-17.391-23.531-25.35-35.531
                                    C125.79,342.867,123.741,341.268,119.341,341.447z"/>
                                <path fill="#FFFFFF" d="M21.35,132.609c0,12.229,0.181,23.38-0.1,34.52c-0.09,3.7,1.15,6.271,4.2,7.73
                                    c5.66,2.729,11.33,5.63,17.29,7.539c25.319,8.09,51.511,9.66,77.87,9.98c3.701,0.049,5.66-1.17,7.271-4.42
                                    c1.898-3.86,4.17-7.64,6.828-11.01c7.811-9.86,15.891-19.51,24.291-29.78C112.19,152.1,66.03,151.339,21.35,132.609z"/>
                                <path fill="#FFFFFF" d="M342.562,124.75c-26.07,8.15,27.289,20.83,41.369,31.57c-0.08,0.23,0.189-0.199,0.189-0.641
                                    c0.021-14.479,0.021-28.949,0.021-43.169C369.671,116.759,355.341,120.98,342.562,124.75z"/>
                                <path fill="#FFFFFF" d="M21.36,69.331c0,11.37,0.25,22.37-0.12,33.339c-0.15,4.391,1.46,7.28,5.09,8.73
                                    c8.9,3.59,17.721,7.82,27,9.92c41.651,9.43,83.562,9.42,125.29,0.721c10.111-2.111,19.75-6.65,29.42-10.511
                                    c2.15-0.86,5-3.53,5.07-5.469c0.471-12.21,0.24-24.45,0.24-36.69C149.12,91.78,85.501,91.84,21.36,69.331z"/>
                                <path fill="#FFFFFF" d="M379.281,90.57c6.451-2.641,6.41-5.969,0.27-9.15c-6.738-3.49-13.83-6.6-21.1-8.71
                                    c-28.98-8.39-58.77-9.25-88.629-7.96c-11.701,0.5-23.352,2.32-35.09,3.55c0,11.78,0,22.581,0,33.85
                                    c2.33,0.5,4.26,1.04,6.229,1.311c35.391,4.85,70.771,5.08,105.84-2.121C357.912,99.07,368.742,94.881,379.281,90.57z"/>
                                <path fill="#FFFFFF" d="M154.181,23.561c-24.561-2.83-49.15-2.851-73.711,0.02c-16.689,1.96-33.2,4.89-48.64,11.92
                                    c-3.8,1.74-7.24,4.279-12.3,7.311c4.78,2.969,7.909,5.379,11.399,7.01C47.4,57.53,65.079,60.55,82.95,62.46
                                    c22.561,2.42,45.17,2.56,67.75,0.069c17.711-1.949,35.221-4.889,51.66-12.129c3.98-1.75,7.551-4.42,12.74-7.51
                                    c-4.869-3.021-8.141-5.561-11.811-7.221C187.69,28.61,171.05,25.51,154.181,23.561z"/>
                            </g>
                            <path d="M150.251,377.473c1.109,1.525,2.221,3.055,3.33,4.596c-3.721,0.389-7.961,0.99-12.221,1.27
                                c-29.359,1.939-58.6,1.199-87.29-5.9c-9.41-2.33-18.42-6.4-27.5-9.939c-3.891-1.52-5.49-4.541-5.34-9.07
                                c0.35-10.971,0.109-21.949,0.109-33.449c5.61,1.939,10.73,3.789,15.9,5.479c26.689,8.682,54.11,12.16,82.101,10.99
                                c2.586-0.105,4.352,0.422,5.869,1.605c-4.529-8.34-8.354-17.111-11.365-26.242c0.182,0.873,0.365,1.758,0.547,2.627
                                c-7.361,0-15.4,0.93-23.15-0.189c-16.393-2.359-32.631-5.779-48.921-8.801c-0.971-0.17-1.9-0.6-2.84-0.93
                                c-17.541-6.07-18.08-6.82-18.12-25.23c-0.021-7.609,0-15.219,0-23.479c27.479,11.1,55.449,16.779,84.32,16.799
                                c0.287,1.375,0.57,2.738,0.855,4.107c-0.363-3.965-0.584-7.971-0.648-12.01c-0.1-6.262,0.174-12.441,0.775-18.527
                                c-0.359,1.65-0.721,3.301-1.082,4.961c-24.961-1.111-50.131-3.5-73.931-13.59c-1.67-0.711-3.25-1.641-4.87-2.48
                                c-3.88-2-5.71-4.811-5.55-9.541c0.38-10.959,0.119-21.939,0.119-33.699c30.431,11.721,61.462,17.731,93.542,16.67
                                c-0.111,0.508-0.221,1.01-0.332,1.516c2.814-8.161,6.275-16.024,10.318-23.534c-1.127,0.644-2.494,0.922-4.268,0.898
                                c-26.359-0.32-52.551-1.891-77.87-9.98c-5.96-1.909-11.63-4.81-17.29-7.539c-3.05-1.46-4.29-4.03-4.2-7.73
                                c0.28-11.14,0.1-22.29,0.1-34.52c44.681,18.729,90.841,19.49,137.651,14.56c-1.078,1.318-2.15,2.627-3.219,3.926
                                c28.352-26.89,66.6-43.626,108.973-44.256c27.494-0.409,53.574,6.286,76.461,18.364c0.426-0.152,0.867-0.303,1.346-0.453
                                c12.779-3.77,27.109-7.99,41.58-12.24c0,14.221,0,28.69-0.021,43.169c0,0.441-0.27,0.871-0.189,0.641
                                c-1.1-0.838-2.438-1.688-3.957-2.546c9.215,9.189,17.324,19.482,24.094,30.661l1.373-1.276c-0.129-30.819-0.529-61.659,0.1-92.468
                                c0.271-13.03-5.029-22.31-15.869-27.629c-11.221-5.511-23.109-10.391-35.25-13.221c-31.83-7.439-64.221-8.68-96.76-5.5
                                c-7.43,0.721-14.84,1.551-23.121,2.42c1.25-13.119-5.648-21.46-15.779-26.51c-10.45-5.21-21.52-9.87-32.85-12.53
                                C142.86-2.39,99.431-2.099,56.09,5.86C41.21,8.6,26.61,12.76,13.801,21.401C4.84,27.43-0.029,35.151,0,46.731
                                c0.29,104.458,0.25,208.927,0.051,313.386c-0.021,10.07,4.129,17.17,11.77,22.861c9.09,6.77,19.51,10.74,30.33,13.529
                                c42.56,10.949,85.54,11.641,128.729,4.42l3.094-2.871C165.425,391.98,157.478,385.08,150.251,377.473z M234.732,68.3
                                c11.738-1.229,23.389-3.05,35.09-3.55c29.859-1.29,59.648-0.43,88.629,7.96c7.27,2.11,14.361,5.22,21.1,8.71
                                c6.141,3.181,6.182,6.509-0.27,9.15c-10.539,4.311-21.369,8.5-32.48,10.77c-35.068,7.201-70.449,6.971-105.84,2.121
                                c-1.969-0.271-3.898-0.811-6.229-1.311C234.732,90.881,234.732,80.081,234.732,68.3z M213.11,106.061
                                c-0.07,1.939-2.92,4.609-5.07,5.469c-9.67,3.86-19.309,8.399-29.42,10.511c-41.729,8.699-83.639,8.709-125.29-0.721
                                c-9.279-2.1-18.1-6.33-27-9.92c-3.63-1.45-5.24-4.339-5.09-8.73c0.37-10.97,0.12-21.969,0.12-33.339
                                c64.141,22.509,127.76,22.45,191.99,0.04C213.351,81.611,213.581,93.851,213.11,106.061z M31.83,35.5
                                c15.439-7.03,31.95-9.96,48.64-11.92c24.561-2.87,49.15-2.85,73.711-0.02c16.869,1.949,33.51,5.049,49.109,12.109
                                c3.67,1.66,6.941,4.2,11.811,7.221c-5.189,3.09-8.76,5.76-12.74,7.51c-16.439,7.24-33.949,10.18-51.66,12.129
                                c-22.58,2.49-45.189,2.351-67.75-0.069C65.079,60.55,47.4,57.53,30.93,49.821c-3.49-1.631-6.619-4.041-11.399-7.01
                                C24.59,39.78,28.03,37.241,31.83,35.5z"/>
                            <g id="XMLID_1_">
                                <g>
                                    <path fill="#FFFFFF" d="M404.681,264.049c1.59,77.469-59.279,139.68-138.221,141.238c-72.59,1.441-136.77-60.43-138.26-133.27
                                        c-1.609-78.439,58.111-141.438,135.571-143.028C339.921,127.429,403.121,187.998,404.681,264.049z"/>
                                    <path fill="#A1B526" d="M428.529,263.684c1.293,94.27-74.789,165.865-161.773,165.199
                                        c-87.43,1.281-161.127-70.879-162.532-159.146c-1.436-89.671,70.303-163.216,160.51-164.56
                                        C353.255,103.863,427.306,175.397,428.529,263.684z M266.46,405.287c78.941-1.559,139.811-63.77,138.221-141.238
                                        c-1.561-76.051-64.76-136.62-140.91-135.059c-77.459,1.59-137.18,64.588-135.571,143.028
                                        C129.69,344.857,193.87,406.729,266.46,405.287z"/>
                                </g>
                                <g>
                                </g>
                            </g>
                            <path d="M255.957,326.395c0-16.283,0-32.242,0-48.561
                                c-1.715-0.439-3.439-0.908-5.176-1.32c-21.092-5.008-37.776-25.34-37.401-45.592c0.381-20.602,16.981-39.646,38.872-44.257
                                c2.848-0.6,3.852-1.495,3.947-4.426c0.229-6.898,4.688-11.528,10.551-11.484c6.15,0.046,10.406,4.885,10.615,12.05
                                c0.027,0.982,0.107,1.964,0.139,2.504c6.041,2.16,12.006,3.723,17.449,6.359c11.223,5.438,19.037,14.327,23.834,25.834
                                c3.053,7.324,0.84,13.582-5.629,16.096c-6.248,2.43-12.047-0.643-14.648-8.039c-3.09-8.785-9.027-14.555-17.775-17.535
                                c-0.621-0.211-1.281-0.336-1.934-0.42c-0.311-0.041-0.645,0.11-1.191,0.219c0,16.039,0,32.115,0,48.15
                                c27.672,4.275,42.422,25.076,43.529,46.344c1.051,20.174-15.682,37.574-43.408,45.65c-1.213,10.912-4.064,14.752-10.982,14.779
                                c-6.885,0.029-9.576-3.543-11.012-14.354c-5.752-2-11.594-3.465-16.928-6.002c-11.773-5.6-19.92-14.807-24.59-27.008
                                c-2.607-6.813-0.143-12.703,6.225-15.094c5.887-2.209,11.578,0.777,14.119,7.408C238.708,318.52,245.576,324.68,255.957,326.395z
                                M255.945,207.613c-11.93,1.144-21.695,12.971-21.516,25.313c0.131,9.029,11.096,19.385,21.516,20.18
                                C255.945,238.031,255.945,222.973,255.945,207.613z M277.572,325.309c11.738-1.818,21.539-12.188,21.08-21.998
                                c-0.563-12.041-10.113-22.855-21.08-23.625C277.572,294.777,277.572,309.855,277.572,325.309z"/>
                        </g>
                    </svg>
                );
            case 'pack':
                return (
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            width="653.925px" height="570.384px" viewBox="0 0 653.925 570.384"
                            >
                        <g>
                            <g>
                                <polygon fill="#FFFFFF" points="460.399,405.714 459.796,559.952 559.795,481.472 562.399,324.714 		"/>
                                <path d="M459.794,569.873c-1.482,0-2.973-0.331-4.355-1.008c-3.418-1.67-5.579-5.147-5.564-8.952l0.604-154.237
                                    c0.012-3.013,1.392-5.856,3.751-7.73l102-81c3.005-2.386,7.117-2.825,10.558-1.129c3.441,1.697,5.596,5.227,5.532,9.063
                                    l-2.604,156.758c-0.049,2.988-1.443,5.795-3.795,7.64l-99.999,78.479C464.14,569.155,461.975,569.873,459.794,569.873z
                                    M470.302,410.52l-0.505,128.971l80.157-62.906l2.177-131.047L470.302,410.52z"/>
                            </g>
                            <g>
                                <polygon fill="#FFFFFF" points="460.399,405.714 106.87,181.047 105.211,338.316 460.739,560.463 		"/>
                                <path d="M460.74,570.384c-1.827,0-3.652-0.504-5.258-1.507L99.954,346.73c-2.933-1.833-4.7-5.061-4.664-8.519l1.66-157.269
                                    c0.038-3.601,2.024-6.899,5.19-8.616c3.165-1.717,7.013-1.585,10.052,0.347l353.529,224.667c2.858,1.816,4.593,4.965,4.601,8.352
                                    l0.34,154.749c0.008,3.61-1.946,6.939-5.102,8.693C464.058,569.969,462.397,570.384,460.74,570.384z M115.19,332.853
                                    l335.589,209.688l-0.289-131.368L116.603,198.987L115.19,332.853z"/>
                            </g>
                            <g>
                                <polygon fill="#FFFFFF" points="42.871,100.048 106.87,181.047 206.871,102.568 144.87,19.048 		"/>
                                <path d="M106.877,190.969c-2.932,0-5.833-1.293-7.791-3.771l-64-81c-3.391-4.292-2.668-10.519,1.615-13.92l102-81
                                    c2.104-1.671,4.797-2.417,7.461-2.067c2.665,0.35,5.073,1.766,6.675,3.923l62,83.52c3.205,4.316,2.389,10.399-1.84,13.718
                                    l-100.001,78.48C111.179,190.277,109.021,190.969,106.877,190.969z M56.791,101.663l51.733,65.475l84.623-66.412l-50.134-67.535
                                    L56.791,101.663z"/>
                            </g>
                            <g>
                                <polygon fill="#FFFFFF" points="460.399,405.714 106.87,181.047 206.871,102.568 562.399,324.714 		"/>
                                <path d="M460.398,415.635c-1.843,0-3.69-0.512-5.32-1.548L101.549,189.421c-2.718-1.727-4.428-4.666-4.588-7.881
                                    s1.251-6.309,3.784-8.297l100-78.479c3.287-2.58,7.839-2.822,11.382-0.609l355.529,222.147c2.734,1.707,4.467,4.637,4.648,7.854
                                    s-1.211,6.323-3.735,8.328l-102,81C464.771,414.912,462.589,415.635,460.398,415.635z M124.026,180.195l335.734,213.358
                                    l85.466-67.87L207.518,114.671L124.026,180.195z"/>
                            </g>
                            <g>
                                <polygon fill="#FFFFFF" points="460.399,405.714 544.002,408.941 644.002,330.461 562.399,324.714 		"/>
                                <path d="M544.002,418.862c-0.127,0-0.254-0.002-0.383-0.007l-83.603-3.227c-4.146-0.16-7.756-2.884-9.047-6.828
                                    c-1.29-3.944,0.01-8.274,3.26-10.855l102-81c1.942-1.542,4.396-2.297,6.866-2.128l81.604,5.748
                                    c4.092,0.288,7.586,3.065,8.789,6.988c1.201,3.922-0.133,8.18-3.361,10.713l-100,78.479
                                    C548.377,418.119,546.22,418.862,544.002,418.862z M487.537,396.833l53.207,2.053l76.882-60.337l-52.077-3.667L487.537,396.833z"
                                    />
                            </g>
                            <g>
                                <polygon fill="#FFFFFF" points="460.399,405.714 106.87,181.047 9.922,202.778 365.451,424.925 		"/>
                                <path d="M365.451,434.846c-1.846,0-3.669-0.515-5.257-1.507L4.665,211.192c-3.413-2.133-5.199-6.116-4.522-10.083
                                    c0.677-3.967,3.683-7.132,7.61-8.013l96.948-21.731c2.57-0.577,5.267-0.107,7.491,1.308l353.529,224.667
                                    c3.417,2.171,5.171,6.2,4.435,10.181c-0.738,3.98-3.82,7.114-7.789,7.917l-94.947,19.211
                                    C366.767,434.782,366.107,434.846,365.451,434.846z M35.678,207.173l331.678,207.245l66.931-13.542L105.023,191.629
                                    L35.678,207.173z"/>
                            </g>
                            <g>
                                <polygon fill="#FFFFFF" points="609.652,234.589 256.123,9.921 206.871,102.568 562.399,324.714 		"/>
                                <path d="M562.4,334.635c-1.843,0-3.667-0.513-5.258-1.507l-355.53-222.147c-4.419-2.761-5.949-8.47-3.503-13.071l49.252-92.646
                                    c1.299-2.441,3.557-4.23,6.229-4.936c2.674-0.706,5.521-0.264,7.854,1.219l353.53,224.667c4.354,2.768,5.861,8.411,3.465,12.98
                                    l-47.254,90.125c-1.281,2.446-3.525,4.247-6.19,4.97C564.141,334.522,563.268,334.635,562.4,334.635z M219.973,99.056
                                    l338.609,211.573l38.045-72.564L259.851,24.045L219.973,99.056z"/>
                            </g>
                            <path d="M206.871,250.968c-5.48,0-9.922-4.441-9.922-9.921V105.048c0-5.479,4.442-9.921,9.922-9.921c5.479,0,9.92,4.442,9.92,9.921
                                v135.999C216.791,246.526,212.35,250.968,206.871,250.968z"/>
                        </g>
                    </svg>
                );
            case 'toner':
                return (
                    <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" x="0px"
                            y="0px" width="608.457px" height="414.097px" viewBox="0 0 608.457 414.097"
                            >
                        <g>
                            <path d="M80.937,33.235c-1.082-5.14-2.019-9.365-2.853-13.61
                                c-1.379-7.019,1.129-10.928,7.997-12.353c2.271-0.471,4.6-0.728,6.916-0.894c11.453-0.821,22.912-1.562,34.366-2.363
                                c8.635-0.604,17.267-1.243,25.898-1.892c2.819-0.212,5.629-0.668,8.448-0.72c3.294-0.061,6.593,0.199,10.659,0.348
                                c2.109-1.795,5.509-2.114,8.747-1.392c2.499,0.557,4.825,2.083,7.104,3.394c9.898,5.693,19.746,11.476,29.623,17.208
                                c11.613,6.741,23.221,13.493,34.862,20.186c12.922,7.43,25.914,14.739,38.811,22.213c11.458,6.641,22.781,13.515,34.246,20.143
                                c12.604,7.288,25.339,14.352,37.952,21.627c11.594,6.688,23.082,13.557,34.654,20.281c5.15,2.993,10.434,5.757,15.587,8.746
                                c8.734,5.065,17.393,10.261,26.122,15.333c5.434,3.158,10.971,6.14,16.408,9.292c8.44,4.894,16.803,9.924,25.259,14.79
                                c9.176,5.281,18.452,10.387,27.628,15.668c8.016,4.614,15.934,9.397,23.93,14.046c5.014,2.914,10.116,5.674,15.138,8.575
                                c12.324,7.121,24.611,14.307,36.939,21.421c5.867,3.387,11.788,6.68,17.695,9.998c4.521,2.539,6.013,6.158,5.147,11.191
                                c-0.828,4.814-3.132,8.745-6.463,12.093c-3.743,3.761-7.697,7.311-12.051,11.416c-0.061,0.635-0.218,1.937-0.302,3.243
                                c-0.881,13.607-1.206,27.282-2.845,40.798c-0.852,7.022-0.633,14.183-2.765,21.156c-2.29,7.494-5.758,14.034-11.104,19.681
                                c-4.546,4.802-9.695,8.98-16.361,9.673c-7.355,0.765-13.529,4.295-20.076,6.982c-5.35,2.196-10.474,4.946-15.847,7.074
                                c-2.411,0.955-5.185,0.992-8.67,1.598c-5.446,4.578-11.54,9.692-17.624,14.819c-1.761,1.483-3.35,3.229-5.278,4.438
                                c-1.782,1.115-3.852,2.033-5.907,2.393c-3.586,0.626-7.256,0.811-10.897,1.085c-12.776,0.964-25.549,1.975-38.333,2.803
                                c-14.156,0.917-14.055,0.665-26.152-6.735c-11.15-6.82-22.578-13.187-33.846-19.818c-1.984-1.168-4.228-2.322-5.553-4.083
                                c-1.9-2.525-4.271-3.509-7.127-3.96c-7.451-1.177-11.766-3.67-16.641-7.892c0.812-0.778,1.507-1.657,2.392-2.261
                                c9.665-6.598,17.976-14.626,24.772-24.115c6.294-8.788,11.154-18.295,14.423-28.721c2.157-6.878,3.284-13.845,4.461-20.877
                                c0.482-2.887,1.801-5.633,2.829-8.713c-1.886-3.112-3.409-6.579-2.566-10.709c1.012-0.517,2.021-1.032,3.063-1.564
                                c0.032-0.568,0.156-1.065,0.07-1.522c-0.679-3.586-1.567-7.141-2.066-10.75c-0.897-6.498,2.037-11.463,6.905-16.023
                                c2.051,3.392,3.967,6.56,6.225,10.292c-1.278,1.988-2.672,4.156-4.23,6.581c1.29,2.247,2.732,4.758,4.206,7.323
                                c-1.513,1.765-2.676,3.12-3.946,4.603c1.105,1.875,1.824,3.718,4.568,4.071c1.211,0.155,2.191,2.107,3.577,3.56
                                c3.994-4.883,7.863-9.595,11.711-14.325c1.574-1.936,2.908-4.108,4.688-5.826c3.862-3.729,3.538-7.896,1.84-12.825
                                c-1.504,0.437-3.027,0.566-4.199,1.277c-3.262,1.981-6.889,2.327-10.5,2.57c-3.27,0.221-5.692-1.755-6.758-4.84
                                c-0.635-1.836,0.017-3.307,1.237-4.545c1.96-1.988,4.266-3.184,7.336-2.178c0.649-1.352,1.197-2.492,1.743-3.628
                                c-0.554-0.512-0.854-0.966-1.273-1.148c-2.428-1.062-4.898-0.865-7.304,0.043c-2.555,0.964-4.757,0.472-7.091-0.89
                                c-13.453-7.852-27.068-15.43-40.416-23.455c-4.653-2.798-8.602-6.744-13.049-9.918c-6.479-4.624-13.454-8.296-20.939-11.171
                                c-4.991-1.917-10.145-3.06-15.183-4.67c-3.264-1.044-6.382-2.695-9.373-4.41c-12.938-7.418-25.794-14.979-38.7-22.451
                                c-8.021-4.644-16.096-9.196-24.12-13.835c-14.06-8.129-28.095-16.3-42.148-24.439c-7.318-4.239-14.644-8.467-21.995-12.648
                                c-0.99-0.563-2.125-1.007-3.244-1.186c-3.791-0.603-12.6,1.35-16.351,3.493c-1.938,4.84-1.194,14.218,1.808,21.485
                                c1.023-0.224,2.125-0.395,3.185-0.71c4.651-1.38,7.979,1.712,11.473,3.655c9.973,5.548,19.767,11.42,29.622,17.178
                                c11.578,6.764,23.129,13.574,34.729,20.3c5.729,3.321,11.564,6.459,17.308,9.756c2.67,1.531,5.235,3.244,8.094,5.027
                                c-8.404,3.75-16.302,7.275-24.807,11.071c-4.438-2.482-9.356-5.169-14.212-7.964c-6.024-3.469-12.024-6.981-17.986-10.557
                                c-6.777-4.064-13.929-7.463-20.301-12.274c-3.389-2.56-7.675-3.896-11.414-6.033c-13.413-7.665-26.748-15.468-40.148-23.156
                                c-8.04-4.612-16.176-9.058-24.215-13.673c-5.727-3.287-11.327-6.793-17.063-10.064c-1.652-0.942-3.575-1.41-5.5-2.143
                                c-3.647,4.339-7.795,7.644-9.491,13.243c48.591,28.063,96.812,55.91,145.539,84.052c-2.842,3.091-5.265,5.729-8.046,8.755
                                c-10.06-4.474-19.127-10.859-28.83-16.045c-9.778-5.228-19.206-11.109-28.799-16.687c-9.584-5.573-19.183-11.121-28.771-16.685
                                c-9.576-5.556-19.129-11.15-28.727-16.668c-9.575-5.504-19.193-10.933-29.151-16.598c-3.986,5.219-5.707,10.947-8.229,16.239
                                c-2.567,5.387-5.052,10.814-7.521,16.248c-2.442,5.378-4.819,10.784-7.336,16.429c5.769,3.977,11.451,7.182,17.536,10.347
                                c1.077-2.708,1.854-4.826,2.757-6.889c1.18-2.694,3.257-4.559,5.876-5.801c4.026-1.909,8.081-3.756,12.465-5.788
                                c4.003,2.069,8.195,4.021,12.184,6.33c33.623,19.459,67.209,38.982,100.602,58.368c-1.136,6.419-1.37,6.987-4.399,10.805
                                c-1.085-0.434-2.352-0.765-3.439-1.402c-11.271-6.604-22.458-13.354-33.777-19.872c-12.916-7.438-25.973-14.628-38.911-22.024
                                c-9.055-5.178-18.166-10.284-26.957-15.886c-4.82-3.071-8.675-2.398-13.02,1.33c-0.674,4.288-0.61,8.958-0.075,13.541
                                c0.172,1.467,1.895,3.046,3.294,4.014c2.849,1.968,5.96,3.557,8.965,5.299c12.445,7.215,24.865,14.476,37.347,21.628
                                c12.78,7.324,25.636,14.517,38.431,21.815c6.901,3.937,13.765,7.941,20.595,12.001c1.227,0.729,2.206,1.873,2.651,2.261
                                c0,4.579,0,8.316,0,12.942c-3.32-1.786-5.89-3.102-8.395-4.529c-11.945-6.811-23.906-13.596-35.799-20.498
                                c-11.294-6.555-22.465-13.321-33.772-19.851c-12.742-7.356-25.585-14.541-38.364-21.835c-1.575-0.899-3.088-1.936-4.526-3.044
                                c-2.648-2.04-2.857-5.088-2.835-8.236c-7.29-4.24-14.451-8.36-21.564-12.562c-3.818-2.256-7.914-4.056-10.894-7.637
                                c-1.199-1.441-3.249-2.198-4.954-3.193c-6.994-4.085-13.913-8.313-21.041-12.152c-5.459-2.94-6.527-7.681-6.064-13.077
                                c0.239-2.79,1.059-5.569,1.905-8.262c2.641-8.397,5.513-16.723,8.116-25.132c2.349-7.589,4.33-15.293,6.757-22.856
                                c0.74-2.308,2.176-4.507,3.705-6.429c10.203-12.828,20.459-25.614,30.82-38.314c5.447-6.676,11.04-13.24,16.773-19.669
                                c2.316-2.597,5.377-4.326,8.765-5.477C78.373,35.364,79.563,34.162,80.937,33.235z M578.302,243.522
                                c-1.363-1.021-2.411-1.981-3.612-2.679c-13.171-7.651-26.359-15.273-39.558-22.878c-10.192-5.873-20.401-11.718-30.613-17.558
                                c-5.467-3.127-10.983-6.167-16.424-9.339c-7.865-4.585-15.643-9.32-23.528-13.87c-9.321-5.379-18.76-10.555-28.071-15.951
                                c-14.745-8.546-29.402-17.242-44.143-25.796c-11.447-6.643-22.973-13.148-34.445-19.746c-10.024-5.765-20.009-11.602-30.041-17.354
                                c-8.756-5.02-17.591-9.9-26.322-14.961c-7.866-4.56-15.595-9.358-23.465-13.911c-9.599-5.551-19.309-10.908-28.921-16.435
                                c-11.462-6.591-22.878-13.26-34.3-19.92c-6.567-3.829-13.074-7.762-19.668-11.545c-4.708-2.7-9.516-5.228-14.011-7.686
                                c-2.696,1.385-4.88,2.506-7.123,3.659c-1.67-1.011-3.257-1.672-4.448-2.765c-1.639-1.503-3.428-1.863-5.509-1.709
                                c-11.953,0.879-23.909,1.705-35.86,2.598c-10.447,0.781-20.889,1.627-31.333,2.44c-1.799,0.14-3.6,0.255-5.202,0.367
                                c-2.348,2.917-0.114,5.399,0.067,7.915c0.206,2.862,1.907,4.793,4.421,6.209c5.048,2.841,9.982,5.885,15.002,8.776
                                c15.349,8.841,30.731,17.625,46.071,26.48c12.479,7.204,24.895,14.52,37.38,21.711c11.486,6.616,23.063,13.072,34.537,19.707
                                c16.355,9.456,32.628,19.056,48.985,28.508c11.75,6.791,23.584,13.436,35.374,20.158c9.639,5.494,19.277,10.988,28.896,16.517
                                c9.053,5.204,18.081,10.45,27.117,15.683c11.604,6.72,23.201,13.447,34.805,20.167c6.877,3.982,13.773,7.93,20.618,11.967
                                c0.796,0.47,1.663,1.316,1.896,2.154c0.838,3.016,1.012,6.082-0.282,9.716c-5.701-4.517-8.971-10.688-14.294-15.231
                                c-1.286,1.01-2.401,1.886-3.845,3.018c8.479,9.975,16.542,19.799,26.405,28.446c1.321-1.487,2.266-2.55,3.001-3.378
                                c-1.069-3.73-6.515-4.603-4.979-9.463c5.833-1.623,11.688,2.462,17.225-0.756c15.898,9.146,31.606,18.183,47.95,27.584
                                c0.258-2.1,0.399-3.354,0.566-4.605c0.859-6.447,1.983-7.337,7.983-9.344c1.863-0.623,3.919-0.78,5.902-0.912
                                c6.973-0.465,13.954-0.796,20.929-1.231c12.795-0.8,25.588-1.632,38.381-2.465C573.951,245.674,576.112,245.562,578.302,243.522z
                                M505.834,290.658c-1.173-1.107-2.696-2.028-3.455-3.379c-1.3-2.313-3.084-2.666-5.436-2.492c-4.181,0.31-7,2.865-9.544,5.713
                                c-3.973,4.446-7.829,9.004-11.573,13.644c-9.875,12.238-19.653,24.555-29.493,36.82c-2.396,2.986-4.732,6.012-5.998,9.67
                                c-0.755,2.18-1.217,4.458-1.9,6.665c-2.599,8.391-5.264,16.76-7.825,25.161c-0.416,1.365-0.432,2.853-0.676,4.599
                                c1.511,0.096,2.643,0.298,3.755,0.218c11.447-0.826,22.89-1.72,34.338-2.545c4.482-0.322,8.973-0.643,13.464-0.739
                                c4.108-0.089,5.085,0.816,6.246,4.823c0.128,0.439,0.469,0.816,0.794,1.361c0.665-0.048,1.489,0.146,1.911-0.179
                                c2.883-2.224,5.807-4.426,8.425-6.94c0.729-0.7,1.013-2.69,0.559-3.637c-1.24-2.581-1.034-5.14-0.877-7.809
                                c0.343-5.808,0.689-11.62,0.791-17.435c0.134-7.653,0.033-15.312,0.033-22.096c4.809-6.763,12.011-9.731,19.199-8.553
                                c1.6,0.262,3.259,0.863,4.676-0.451c0.353-2.872-1.003-27.12-1.658-29.884c-0.648-2.732-2.026-3.749-5.093-3.527
                                C513.18,289.907,509.874,290.276,505.834,290.658z M399.081,314.535c5.603,4.803,11.854,7.526,17.594,11.001
                                c5.896,3.569,11.978,6.831,18.154,10.328c4.602-5.507,9.022-10.622,13.252-15.89c11.789-14.685,23.005-29.843,36.189-43.756
                                c-0.991-0.859-1.98-2.026-3.228-2.749c-8.281-4.803-16.639-9.472-24.924-14.268c-3.295-1.908-6.782-2.305-9.984-0.524
                                c-2.543,1.413-4.896,3.49-6.761,5.745c-10.735,12.979-21.321,26.083-31.91,39.183C404.677,307.051,402.063,310.636,399.081,314.535
                                z M142.058,130.399c1.056-4.277,0.317-7.815-0.336-11.382c-1.638-8.933,3.29-18.169,12.193-20.656
                                c1.207-0.337,2.37-0.832,4.249-1.501c-1.823-1.463-2.982-2.653-4.365-3.457c-4.417-2.568-8.956-4.927-13.379-7.486
                                c-9.153-5.296-18.239-10.711-27.408-15.981c-2.278-1.309-4.638-2.546-7.53-1.922c-2.755,0.594-5.595,0.787-8.355,1.355
                                c-2.426,0.5-4.9,1.023-7.163,1.984c-3.803,1.616-4.634,3.284-4.011,7.485c0.874,5.901,1.827,11.794,2.916,17.66
                                c0.325,1.751,1.217,3.397,1.702,4.698c8.902,5.155,17.288,10.143,25.802,14.902C124.811,120.815,132.892,126.218,142.058,130.399z
                                M392.174,327.542c-4.819,15.498-9.672,31.102-14.659,47.139c5.085,4.927,11.712,7.36,17.521,10.993
                                c5.818,3.639,11.914,6.834,18.214,10.409c0.8-1.735,1.565-3.012,1.993-4.393c1.268-4.089,2.325-8.243,3.604-12.328
                                c2.57-8.212,5.251-16.39,7.846-24.594c0.94-2.975,2.101-5.96,0.933-8.955c-10.214-5.933-20.235-11.7-30.188-17.585
                                C395.75,327.231,394.23,326.577,392.174,327.542z M63.506,120.604c2.343-2.647,4.369-4.823,6.262-7.108
                                c2.002-2.417,3.979-4.869,5.766-7.445c0.918-1.326,2.058-3.005,1.951-4.44c-0.34-4.62-1.157-9.209-1.9-13.792
                                c-0.505-3.11-1.413-6.167-1.761-9.29c-0.967-8.688,4.44-16.52,12.805-18.685c3.693-0.956,7.434-1.736,11.17-2.514
                                c1.492-0.311,3.085-0.334,3.107-2.882c-4.2-2.441-8.593-4.979-12.97-7.542c-2.215-1.297-4.278-1.1-6.614-0.031
                                c-4.732,2.165-7.758,6.067-10.885,9.913C58.863,71.02,47.308,85.271,35.712,99.488c-1.221,1.496-2.209,2.926-1.11,5.273
                                c1.685,1.026,3.624,2.251,5.604,3.406c6.142,3.581,12.27,7.188,18.471,10.663C60.172,119.667,61.928,120.039,63.506,120.604z
                                M36.831,177.044c6.626-14.623,13.342-29.445,20.265-44.723c-9.631-6.545-19.527-11.638-29.282-17.26
                                c-0.797,0.965-1.561,1.507-1.783,2.218c-4.602,14.686-9.159,29.386-13.652,44.106c-0.267,0.875,0.079,1.938,0.13,2.762
                                C19.501,169.743,32.607,176.826,36.831,177.044z M593.023,255.673c-27.077,2.062-54.082,4.118-80.977,6.166
                                c-0.652,4.961-0.261,6.374,3.061,11.363c12.548-1.021,25.277-2.056,37.893-3.081c3.452-3.217,6.71-6.281,11.063-7.847
                                c2.675-0.962,5.185-1.21,7.578,0.565c2.236,1.658,4.434,3.37,6.555,4.987C584.768,267.08,592.188,261.08,593.023,255.673z
                                M523.133,372.092c5.231-2.295,10.172-4.462,15.557-6.824c-0.436-3.663-1.157-7.232-1.201-10.811
                                c-0.044-3.58,0.591-7.168,0.914-10.602c-5.214-2.245-10.118-2.617-14.277-1.234C522.395,348.451,521.538,368.027,523.133,372.092z
                                M569.208,311.156c1.118-4.446,1.918-8.449,0.263-13.077c-10.616,3.91-20.99,7.033-30.638,11.629
                                c-0.055,2.354-0.481,4.211,1.541,5.679c4.604-1.943,8.229-5.903,13.455-6.837C559.129,307.601,563.977,308.803,569.208,311.156z
                                M549.826,290.853c-0.857-2.724-1.632-5.188-2.423-7.701c-3.897-0.43-7.408-0.667-10.836,1.41c1.104,3.877-0.271,8.064,2.6,11.775
                                C542.492,294.158,547.116,295.113,549.826,290.853z M560.601,356.445c-3.648,0-6.851,0-10.15,0
                                c-0.098,0.374-0.34,0.844-0.308,1.295c0.045,0.639,0.294,1.263,0.438,1.821C555.084,361.514,557.474,360.776,560.601,356.445z
                                M572.366,337.326c2.216-3.766,1.386-6.944-1.36-10.335c-1.125,0.112-2.455,0.246-3.984,0.398
                                C568.918,330.917,570.564,333.975,572.366,337.326z M562.099,282.138c3.13-3.342,3.13-3.342,2.524-6.035
                                C563.1,277.731,561.413,278.981,562.099,282.138z"/>
                            <path d="M396.014,233.492c0,0,32.506,17.87,32.426,20.732s30.681-12.251,30.681-12.251
                                l-53.55-30.958L396.014,233.492z"/>
                            <circle fill="#FFFFFF" cx="290.578" cy="284.128" r="102.743"/>
                            <path d="M370.02,146.158c-2.972,4.253-8.069,4.86-12.336,6.819
                                c-2.026,0.931-3.79,1.776-5.204,3.993c4.571,2.65,8.777,5.746,13.478,7.57c2.878,1.116,6.533,0.525,9.808,0.276
                                c9.616-0.732,19.215-1.702,28.827-2.511c1.476-0.124,3.046-0.128,4.46,0.241c2.378,0.622,4.393,3.239,4.385,5.562
                                c-0.01,2.724-1.304,4.689-3.87,5.844c-4.382,1.97-8.722,4.03-13.068,6.077c-0.272,0.128-0.443,0.471-1.071,1.17
                                c1.568,1.077,3.193,2.068,4.678,3.237c5.047,3.971,10.574,5.713,17.124,5.062c9.764-0.972,19.583-1.381,29.379-2.024
                                c1.328-0.087,2.669-0.284,3.985-0.182c3.07,0.239,5.098,2.042,5.631,4.793c0.444,2.293-0.704,4.872-3.219,6.159
                                c-3.97,2.033-8.104,3.744-12.103,5.727c-0.882,0.438-1.482,1.44-2.506,2.48c5.041,3.643,9.922,6.547,15.225,8.241
                                c2.624,0.838,5.878-0.29,8.846-0.542c2.812-0.238,5.619-0.531,8.434-0.733c6.305-0.454,12.61-0.874,18.918-1.287
                                c1.326-0.087,2.674-0.235,3.985-0.099c3.107,0.324,5.41,2.638,5.58,5.406c0.199,3.241-1.674,5.692-5.242,6.105
                                c-5.948,0.688-11.933,1.057-17.9,1.566c-6.457,0.552-12.917,1.065-19.367,1.685c-3.697,0.354-7.251,0.328-10.595-1.682
                                c-3.836-2.305-7.786-4.418-11.667-6.65c-1.012-0.582-1.946-1.297-2.287-2.585c-1.238-0.255-2.65-0.209-3.687-0.812
                                c-14.456-8.395-28.837-16.919-43.286-25.324c-9.009-5.239-18.124-10.295-27.153-15.5c-11.615-6.694-23.166-13.501-34.795-20.173
                                c-9.619-5.52-19.344-10.854-28.96-16.379c-8.74-5.02-17.364-10.242-26.099-15.272c-15.484-8.917-31.046-17.7-46.515-26.644
                                c-12.871-7.443-25.625-15.09-38.491-22.544c-8.013-4.642-16.146-9.074-24.205-13.635c-3.449-1.952-6.892-3.926-10.245-6.034
                                c-1.237-0.777-2.212-1.969-3.221-2.893c-0.623-3.063-0.904-5.829,2.052-8.3c15.791-1.675,31.911-3.901,47.834-3.408
                                c4.687,5.228,4.15,8.44-1.537,11.296c-2.803,1.408-5.736,2.554-8.597,3.85c-1.481,0.671-2.933,1.408-4.982,2.397
                                c1.216,1.259,1.98,2.279,2.962,3.006c1.057,0.782,2.461,1.115,3.464,1.945c6.017,4.978,12.901,5.161,20.219,4.27
                                c8.911-1.085,17.879-1.716,26.827-2.484c2.168-0.186,4.243-0.091,5.982,1.728c1.885,1.971,1.871,4.117,1.252,6.81
                                c-4.294,5.153-11.573,5.581-17.377,9.684c0.697,0.917,1.148,1.973,1.947,2.475c2.803,1.762,5.635,3.531,8.636,4.906
                                c2.021,0.926,4.355,1.707,6.532,1.666c11.313-0.21,22.612-0.839,33.833-2.429c0.493-0.07,0.996-0.07,1.495-0.087
                                c7.089-0.25,9.483,3.317,6.789,10.108c-5.037,3.604-11.521,4.552-17.234,8.911c5.597,3.134,10.347,6.057,15.353,8.445
                                c1.625,0.775,3.928,0.279,5.905,0.144c6.465-0.441,12.923-0.988,19.384-1.483c4.144-0.318,8.313-0.431,12.427-0.974
                                c4.399-0.581,7.434,0.876,9.247,5.022c-0.403,4.344-3.913,5.937-7.094,7.544c-3.238,1.636-6.739,2.752-9.991,4.043
                                c-1.221,1.792-0.55,3.004,0.922,3.871c3.427,2.015,6.784,4.21,10.397,5.821c1.853,0.826,4.21,0.849,6.311,0.73
                                c10.955-0.621,21.901-1.404,32.85-2.13c0.499-0.033,0.998-0.072,1.497-0.082C369.408,137.843,370.782,139.432,370.02,146.158z"/>
                            <path d="M440.544,232.389c-2.475-0.141-4.949-0.281-7.45-0.424
                                c-0.379-0.956-0.733-1.524-0.808-2.127c-0.073-0.602,0.136-1.237,0.254-2.107C436.049,227.734,438.149,230.086,440.544,232.389z"/>
                            <path fill="#A1B526" d="M290.578,198.052c-47.539,0-86.076,38.538-86.076,86.076
                                c0,47.538,38.538,86.076,86.076,86.076c47.538,0,86.076-38.538,86.076-86.076C376.654,236.589,338.116,198.052,290.578,198.052z
                                M299.063,310.444l-17.913,17.914l-17.914-17.914l-27.813-27.813l17.914-17.914l27.813,27.813l46.669-46.669l17.913,17.914
                                L299.063,310.444z"/>
                        </g>
                    </svg>
                );
            case 'laur':
                    return (
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                width="425.197px" height="351.269px" viewBox="0 0 425.197 351.269"
                                >
                            <g>
                                <path fill="#231F20" d="M233.211,320.824c2.352-20.272,15.65-28.842,35.796-27.841
                                    c-0.82,2.6-1.507,5.147-2.425,7.608c-1.964,5.268-4.618,10.143-8.844,13.979c-5.66,5.14-12.477,6.89-19.943,6.604
                                    C236.306,321.118,234.822,320.95,233.211,320.824z"/>
                                <path fill="#231F20" d="M149.287,349.336c2.636-5.318,5.701-9.691,9.542-13.536
                                    c3.646-3.65,7.849-6.347,12.932-7.413c6.568-1.377,12.875-0.33,19.074,2.33c-2.906,7.326-6.922,13.617-13.953,17.432
                                    c-5.817,3.156-12.138,3.54-18.554,2.798C155.416,350.61,152.542,349.927,149.287,349.336z"/>
                                <path fill="#231F20" d="M156.24,292.969c21.758-1.112,33.955,9.38,35.628,27.599
                                    c-0.387,0.137-0.796,0.378-1.224,0.419c-8.557,0.811-16.592-0.396-23.208-6.453c-4.513-4.133-7.24-9.431-9.24-15.124
                                    C157.472,297.345,156.919,295.221,156.24,292.969z"/>
                                <path fill="#231F20" d="M239.762,340.325c-2.392-2.813-4.099-6.001-5.326-9.639
                                    c5.266-2.244,10.642-3.305,16.312-2.734c8.497,0.856,14.501,5.753,19.457,12.19c2.103,2.73,3.836,5.745,6.067,9.131
                                    c-3.533,0.645-6.437,1.356-9.383,1.675c-6.18,0.667-12.294,0.429-17.977-2.524c-1.165-0.605-2.197-1.465-3.291-2.208
                                    C243.668,344.252,241.715,342.288,239.762,340.325z"/>
                                <path fill="#231F20" d="M97.936,324.392c2.674-2.172,4.919-4.154,7.325-5.917
                                    c4.951-3.626,10.379-6.164,16.633-6.378c7.177-0.245,13.326,2.522,18.934,6.742c0.598,0.449,1.15,0.96,1.719,1.447
                                    c0.035,0.029,0.029,0.107,0.083,0.336c-2.872,3.732-6.418,6.834-10.669,9.094c-8.972,4.769-17.737,3.057-26.351-1.045
                                    C103.123,327.487,100.783,325.992,97.936,324.392z"/>
                                <path fill="#231F20" d="M282.485,320.587c7.68-7.161,18.811-11.011,29.382-6.659
                                    c5.272,2.171,9.771,5.472,13.961,9.259c1.085,0.981,0.241,1.644-0.434,2.066c-2.154,1.351-4.309,2.753-6.614,3.802
                                    c-5.436,2.473-11.069,4.123-17.171,3.32c-6.372-0.839-11.521-3.982-16.003-8.39C284.537,322.935,283.573,321.778,282.485,320.587z"
                                    />
                                <path fill="#231F20" d="M277.689,312.013c-2.756-19.51,8.918-32.781,27.554-37.313
                                    c-0.221,2.725-0.342,5.366-0.668,7.981c-0.655,5.271-2.042,10.32-4.72,14.977c-3.427,5.959-8.564,9.821-14.875,12.205
                                    C282.631,310.75,280.156,311.297,277.689,312.013z"/>
                                <path fill="#231F20" d="M120.085,274.687c19.235,4.939,29.995,18.049,27.462,37.111
                                    c-6.845-1.01-12.922-3.636-17.809-8.541C121.913,295.404,120.364,285.35,120.085,274.687z"/>
                                <path fill="#231F20" d="M333.506,248.269c2.645,9.741,3.84,19.074-0.73,28.161
                                    c-2.93,5.823-7.682,9.961-13.389,13c-0.349,0.185-1.241-0.063-1.505-0.401c-2.134-2.734-2.392-6.089-2.766-9.343
                                    c-1.271-11.057,3.825-19.325,11.791-26.244C328.914,251.701,331.088,250.155,333.506,248.269z"/>
                                <path fill="#231F20" d="M91.98,301.602c-2.664,0.64-5.305,1.68-7.997,1.841
                                    c-6.691,0.397-12.426-2.399-17.559-6.39c-2.693-2.095-5.072-4.594-7.572-6.933c-0.37-0.347-0.619-0.821-0.959-1.284
                                    c5.923-3.148,12.023-5.297,18.745-5.448c8.206-0.186,14.619,3.431,20.067,9.19c0.707,0.747,1.293,1.608,1.935,2.417
                                    C96.421,297.198,94.201,299.4,91.98,301.602z"/>
                                <path fill="#231F20" d="M104.938,288.687c-0.895-0.483-1.847-0.884-2.675-1.461
                                    c-7.786-5.427-12.351-12.658-12.718-22.358c-0.21-5.56,0.505-10.937,2.246-16.442c8.719,6.092,16.35,12.818,18.083,23.786
                                    c0.565,3.578-0.132,7.361-0.326,11.048c-0.027,0.52-0.482,1.017-0.739,1.525C107.518,286.085,106.228,287.386,104.938,288.687z"/>
                                <path fill="#231F20" d="M29.001,250.588c16.486-6.316,31.706-2.791,39.244,14.385
                                    c-2.559,1.801-5.559,2.474-8.544,3.042c-9.682,1.843-17.409-1.865-23.808-8.827C33.44,256.517,31.337,253.526,29.001,250.588z"/>
                                <path fill="#231F20" d="M396.181,250.29c-1.392,1.963-2.534,3.689-3.793,5.327
                                    c-2.943,3.831-6.303,7.209-10.521,9.682c-5.843,3.425-12.023,3.938-18.446,2.24c-2.211-0.585-4.337-1.482-6.729-2.315
                                    c3.112-6.45,7.031-11.779,13.197-14.916C378.406,245.974,387.078,247.549,396.181,250.29z"/>
                                <path fill="#231F20" d="M67.535,217.3c13.693,11.674,18.312,27.205,8.259,41.774
                                    c-6.329-4.576-11.051-10.356-12.565-18.098C61.599,232.651,63.831,224.85,67.535,217.3z"/>
                                <path fill="#231F20" d="M357.491,217.256c9.254,12.581,4.579,35.871-8.332,41.728
                                    c-4.938-8.047-6.912-16.514-3.399-25.632C348.198,227.02,352.382,221.868,357.491,217.256z"/>
                                <path fill="#231F20" d="M348.177,302.31c-3.707,1.785-7.554,1.363-11.388,0.635
                                    c-3.975-0.756-7.647-2.177-10.525-5.207c-0.563-0.594-1.06-1.021-0.419-1.888c4.292-5.801,9.606-10.26,16.767-11.881
                                    c6.978-1.581,13.682-0.084,20.153,2.66c0.228,0.097,0.372,0.393,0.554,0.596C358.272,292.253,353.224,297.282,348.177,302.31z"/>
                                <path fill="#231F20" d="M415.467,206.276c-2.231,5.635-4.938,10.465-8.737,14.734
                                    c-4.962,5.58-11.197,8.057-18.477,8.116c-2.459,0.02-4.923-0.492-7.718-0.797c1.53-6.395,4.274-11.748,8.929-15.862
                                    C396.893,205.902,405.886,205.367,415.467,206.276z"/>
                                <path fill="#231F20" d="M9.925,206.357c4.452-0.667,8.741-0.557,13.058,0.185
                                    c11.6,1.994,17.975,9.428,21.302,20.154c0.373,1.199-0.007,1.493-1.079,1.727c-10.033,2.186-20.004-0.573-26.43-9.479
                                    c-2.519-3.49-4.327-7.495-6.44-11.275C10.148,207.332,10.096,206.92,9.925,206.357z"/>
                                <path fill="#231F20" d="M54.068,181.756c4.683,6.893,8.205,14.057,8.119,22.457
                                    c-0.073,7.104-3.956,14.643-9.193,18.889C41.946,211.567,41.636,196.472,54.068,181.756z"/>
                                <path fill="#231F20" d="M50.123,113.81c1.331,7.436,1.865,14.684-0.841,21.771
                                    c-2.368,6.199-7.055,10.199-12.896,12.997c-0.781,0.374-1.602,0.668-2.417,0.963c-0.316,0.114-0.668,0.127-0.993,0.185
                                    C27.843,135.473,32.541,121.716,50.123,113.81z"/>
                                <path fill="#231F20" d="M386.147,186.406c-7.062-4.081-12.142-9.432-13.568-17.436
                                    c-0.788-4.421-0.526-8.91,0.694-13.278c0.728-2.605,1.553-5.183,2.442-8.132c5.992,4.343,10.831,9.179,13.247,15.835
                                    C391.867,171.396,390.073,179.008,386.147,186.406z"/>
                                <path fill="#231F20" d="M392.147,149.824c-5.143-1.652-9.556-4.391-12.956-8.618
                                    c-3.438-4.272-4.854-9.303-5.085-14.69c-0.182-4.243-0.058-8.477,1.144-12.61C392.096,121.26,397.564,135.026,392.147,149.824z"/>
                                <path fill="#231F20" d="M50.533,151.566c1.759,3.497,2.164,7.281,2.435,11.113
                                    c0.813,11.5-5.729,19.328-13.816,23.591c-5.993-10.426-6.162-22.358,2.716-31.81c1.872-1.993,4.096-3.655,6.158-5.471
                                    C48.861,149.848,49.697,150.707,50.533,151.566z"/>
                                <path fill="#231F20" d="M424.792,161.458c-1.604,8.066-3.813,15.337-9.444,21.018
                                    c-4.444,4.483-10.049,6.452-16.212,7.052c-0.961,0.095-1.929,0.112-3.149,0.179c0.313-7.363,2.339-13.884,7.42-19.101
                                    C409.143,164.715,416.605,162.64,424.792,161.458z"/>
                                <path fill="#231F20" d="M29.246,189.736c-9.414-0.369-17.222-3.181-22.507-11.091
                                    c-3.41-5.103-5.115-10.823-6.21-17.124c7.913,1.012,15.171,3.119,20.89,8.666C26.801,175.408,28.879,182.109,29.246,189.736z"/>
                                <path fill="#231F20" d="M424.996,114.652c0.532,7.57,0.207,14.847-3.424,21.628
                                    c-3.167,5.917-8.282,9.393-14.383,11.665c-0.759,0.283-1.548,0.485-2.328,0.706c-0.161,0.046-0.346,0.006-0.521,0.008
                                    C400.447,133.763,408.007,120.498,424.996,114.652z"/>
                                <path fill="#231F20" d="M0.354,114.706c16.362,5.603,24.315,18.273,20.667,33.855
                                    C6.766,145.874-1.894,133.575,0.354,114.706z"/>
                                <path fill="#231F20" d="M363.978,209.08c-1.323-2.813-0.951-5.76-0.703-8.701
                                    c0.433-5.135,4.456-14.548,8.182-18.771c1.766,2.747,3.605,5.325,5.147,8.07c2.99,5.322,4.587,11.041,3.72,17.185
                                    c-0.679,4.802-2.255,9.302-5.521,13.047C371.195,216.299,367.586,212.69,363.978,209.08z"/>
                                <path fill="#231F20" d="M416.509,73.065c4.251,14.433,0.077,29.436-15.429,35.431
                                    C394.215,98.108,402.842,78.296,416.509,73.065z"/>
                                <path fill="#231F20" d="M24.37,108.527C10.011,103.139,4.165,89.44,8.681,73.143
                                    C22.395,80.092,30.201,92.556,24.37,108.527z"/>
                                <path fill="#231F20" d="M390.646,112.002c-8.897,0.373-18.253-3.915-21.856-13.105
                                    c-1.526-3.894-2.276-8.095-3.329-12.168c-0.151-0.588-0.021-1.249-0.021-1.88C380.157,85.905,391.834,95,390.646,112.002z"/>
                                <path fill="#231F20" d="M34.599,112.01C33.8,96.851,42.175,86.789,59.903,84.638
                                    c-0.55,7.408-2.148,14.408-7.115,20.088C48.066,110.125,41.769,112.121,34.599,112.01z"/>
                                <path fill="#231F20" d="M383.606,79.343c-14.715,3.126-24.932-4.703-29.189-20.934
                                    c7.315-0.405,14.336,0.229,20.353,4.758C380.097,67.178,382.437,72.96,383.606,79.343z"/>
                                <path fill="#231F20" d="M41.82,79.436c1.837-15.381,13.983-22.642,28.816-20.806
                                    C67.956,70.847,58.561,83.034,41.82,79.436z"/>
                                <path fill="#231F20" d="M399.692,37.302c6.709,13.044,5.208,27.949-8.077,35.904
                                    C383.536,60.321,387.57,46.994,399.692,37.302z"/>
                                <path fill="#231F20" d="M33.576,73.246C22.132,67.711,17.718,47.7,25.573,37.354
                                    C37.439,46.728,41.766,60.218,33.576,73.246z"/>
                                <path fill="#231F20" d="M342.953,0.029c12.433,3.134,21.646,11.979,20.514,28.65
                                    c-4.751-0.747-8.894-2.695-12.556-5.68C344.672,17.917,342.423,8.515,342.953,0.029z"/>
                                <path fill="#231F20" d="M82.285,0c1.985,13.824-8.699,28.757-20.311,28.537
                                    C60.321,14.762,67.615,3.688,82.285,0z"/>
                                <path fill="#231F20" d="M47.085,44.253c-8.494-7.944-10.878-20.441-0.54-33.683
                                    C55.315,20.785,57.388,34.466,47.085,44.253z"/>
                                <path fill="#231F20" d="M378.626,10.471c10.232,13.446,8.263,24.907-0.309,33.774
                                    C369.152,36.178,368.576,22.943,378.626,10.471z"/>
                                <path fill="#231F20" d="M343.306,34.61c12.487-2.49,24.138,0.72,28.224,16.073
                                    C357.631,55.325,347.906,47.596,343.306,34.61z"/>
                                <path fill="#231F20" d="M74.917,46.057c-2.301,3.088-5.547,4.65-9.167,5.485
                                    c-4.05,0.933-8.053,0.453-12.046-0.739c1.502-5.868,4.251-10.816,9.482-14.073c0.51-0.317,1.217-0.32,1.833-0.469
                                    c0.57,0.475,1.184,0.907,1.703,1.433C69.466,40.469,72.188,43.267,74.917,46.057z"/>
                                <path fill="#231F20" d="M74.917,46.057c-2.729-2.79-5.451-5.588-8.196-8.363
                                    c-0.52-0.525-1.133-0.958-1.703-1.433c1.069-0.548,2.082-1.313,3.216-1.604c4.492-1.16,9.026-0.959,13.783-0.218
                                    c-1.036,3.413-2.691,6.286-4.656,8.984C76.661,44.384,75.738,45.184,74.917,46.057z"/>
                                <path fill="#231F20" d="M348.177,302.31c5.047-5.028,10.095-10.057,15.142-15.085
                                    c1.268,0.425,2.536,0.852,4.316,1.449c-1.536,1.649-2.688,2.972-3.932,4.204c-3.779,3.743-8.017,6.844-12.961,8.883
                                    C349.948,302.089,349.036,302.134,348.177,302.31z"/>
                                <path fill="#231F20" d="M363.978,209.08c3.608,3.61,7.217,7.22,10.824,10.83
                                    c-0.754,1.128-1.507,2.255-2.369,3.545c-3.024-2.705-5.247-5.615-6.757-9.007C364.917,212.744,364.533,210.874,363.978,209.08z"/>
                                <path fill="#231F20" d="M91.98,301.602c2.22-2.202,4.441-4.404,6.661-6.606
                                    c0.631,0.786,1.262,1.573,2.176,2.714c-2.955,1.464-5.615,2.788-8.288,4.085C92.407,301.854,92.166,301.671,91.98,301.602z"/>
                                <path fill="#231F20" d="M104.938,288.687c1.291-1.301,2.581-2.602,3.871-3.902
                                    c-0.388,1.767-0.777,3.534-1.264,5.748C106.371,289.702,105.654,289.194,104.938,288.687z"/>
                                <path fill="#231F20" d="M239.762,340.325c1.953,1.963,3.906,3.928,5.859,5.891
                                    C243.668,344.252,241.715,342.288,239.762,340.325z"/>
                                <path fill="#231F20" d="M50.533,151.566c-0.836-0.859-1.671-1.718-2.508-2.576
                                    c0.361-0.397,0.721-0.796,1.41-1.555C49.87,149.074,50.201,150.319,50.533,151.566z"/>
                            </g>
                            <g>
                                <path fill="#9DB60A" d="M143.463,158.765v12.981H91.439V161.42l26.553-25.078c2.818-2.688,4.721-4.999,5.704-6.934
                                    c0.983-1.933,1.475-3.851,1.475-5.753c0-2.754-0.934-4.868-2.803-6.343s-4.607-2.213-8.212-2.213c-3.017,0-5.738,0.575-8.163,1.721
                                    c-2.426,1.148-4.459,2.869-6.097,5.163l-11.605-7.474c2.688-3.999,6.393-7.13,11.113-9.392c4.72-2.262,10.129-3.393,16.227-3.393
                                    c5.114,0,9.589,0.836,13.424,2.507c3.835,1.672,6.818,4.032,8.949,7.081c2.13,3.049,3.196,6.638,3.196,10.769
                                    c0,3.737-0.787,7.245-2.36,10.523c-1.574,3.279-4.622,7.016-9.146,11.211l-15.833,14.948H143.463z"/>
                                <path fill="#9DB60A" d="M164.115,168.698c-4.459-2.818-7.95-6.899-10.474-12.244c-2.524-5.343-3.786-11.718-3.786-19.128
                                    c0-7.408,1.262-13.784,3.786-19.128c2.523-5.343,6.015-9.424,10.474-12.244c4.458-2.818,9.54-4.229,15.244-4.229
                                    s10.784,1.411,15.243,4.229c4.458,2.82,7.949,6.901,10.474,12.244c2.523,5.344,3.786,11.72,3.786,19.128
                                    c0,7.41-1.263,13.785-3.786,19.128c-2.524,5.344-6.016,9.426-10.474,12.244c-4.459,2.82-9.539,4.229-15.243,4.229
                                    S168.573,171.518,164.115,168.698z M189.242,154.044c2.393-3.605,3.59-9.178,3.59-16.718c0-7.539-1.197-13.112-3.59-16.719
                                    c-2.394-3.605-5.688-5.409-9.883-5.409c-4.13,0-7.393,1.804-9.785,5.409c-2.395,3.606-3.59,9.18-3.59,16.719
                                    c0,7.54,1.195,13.113,3.59,16.718c2.392,3.606,5.655,5.409,9.785,5.409C183.554,159.454,186.848,157.651,189.242,154.044z"/>
                                <path fill="#9DB60A" d="M230.89,168.698c-4.46-2.818-7.951-6.899-10.474-12.244c-2.525-5.343-3.787-11.718-3.787-19.128
                                    c0-7.408,1.262-13.784,3.787-19.128c2.522-5.343,6.014-9.424,10.474-12.244c4.457-2.818,9.539-4.229,15.243-4.229
                                    s10.784,1.411,15.243,4.229c4.458,2.82,7.949,6.901,10.474,12.244c2.523,5.344,3.786,11.72,3.786,19.128
                                    c0,7.41-1.263,13.785-3.786,19.128c-2.524,5.344-6.016,9.426-10.474,12.244c-4.459,2.82-9.539,4.229-15.243,4.229
                                    S235.347,171.518,230.89,168.698z M256.017,154.044c2.393-3.605,3.589-9.178,3.589-16.718c0-7.539-1.196-13.112-3.589-16.719
                                    c-2.395-3.605-5.688-5.409-9.884-5.409c-4.131,0-7.393,1.804-9.785,5.409c-2.395,3.606-3.59,9.18-3.59,16.719
                                    c0,7.54,1.195,13.113,3.59,16.718c2.393,3.606,5.654,5.409,9.785,5.409C250.329,159.454,253.623,157.651,256.017,154.044z"/>
                                <path fill="#9DB60A" d="M334.249,158.765v12.981h-52.024V161.42l26.554-25.078c2.817-2.688,4.72-4.999,5.703-6.934
                                    c0.983-1.933,1.476-3.851,1.476-5.753c0-2.754-0.935-4.868-2.803-6.343c-1.869-1.475-4.607-2.213-8.212-2.213
                                    c-3.017,0-5.737,0.575-8.162,1.721c-2.427,1.148-4.46,2.869-6.098,5.163l-11.604-7.474c2.688-3.999,6.393-7.13,11.112-9.392
                                    c4.721-2.262,10.13-3.393,16.228-3.393c5.113,0,9.588,0.836,13.424,2.507c3.835,1.672,6.817,4.032,8.949,7.081
                                    c2.13,3.049,3.196,6.638,3.196,10.769c0,3.737-0.787,7.245-2.36,10.523c-1.573,3.279-4.622,7.016-9.146,11.211l-15.833,14.948
                                    H334.249z"/>
                            </g>
                        </svg>
                    );     
        }
    }

    return choose();
}
export default Svg;