import styled from 'styled-components';

const space = 18;
const size = 15;

const StepsStyle = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    .step{
        width: ${size}px;
        height: ${size}px;
        background-color: #074e6d;
        border-radius: ${size/2}px;
        margin-left: ${space}px;
        position: relative;
        border: 1px solid transparent;
        &:before{
            content: '';
            display: block;
            width: ${space - 6}px;
            height: 1px;
            background-color: #074e6d;
            position:  absolute;
            top: ${parseInt(size/2)}px;
            left: ${-(space - 3)}px;
        }
        &.checked{
            cursor: pointer;
            background-color: var(--color-base-light);
            &:before{
                background-color: var(--color-base-light);
            }
            &:hover{
                border: 1px solid black;
            }
        }
        &.next{
            &:hover{
                border: 1px solid black;
                cursor: pointer;
            }
        }
        
        &:nth-child(1){
            margin-left: 0px;
            &:before{
                display: none;
            }
        }
        &:last-child{
            &.checked{
                background-color: var(--color-lime-dark);
            }
        }
    }
`;	

export default StepsStyle;