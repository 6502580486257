import styled from 'styled-components';
import imgBg from '../../assets/images/top-background.jpg';
import { viewMax , viewSd , viewHdSmall, viewMobile, viewHd } from '../../actions/variables';

const UnderHeaderStyle = styled.div`
    position: relative;
    background-color: var(--color-grey);
    .contentWidth{
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: row;
        padding-bottom: 40px;
        z-index: 5;
        .content{
            max-width: 760px;
            margin-top: 35px;
            h2{
                margin: 0px;
                padding: 0px;
                color: var(--text-color-grey);
                font-weight: 700;
                font-size: 33px;
            }
            p{
                font-size: 26px;
                color: var(--text-color-grey);
                margin: 30px 0px 40px 0px;
            }
        }
    }
    .imageBackground{
        position: absolute !important;
        top: 0px;
        right: 0px;
        z-index: 0;
        width: 55%;
        height: 100%;
        /* background-image: url(${imgBg}); */
        background-repeat: no-repeat;
        background-position: top left !important;
        background-size: cover;
        &:before , &:after{
            background-position: top left !important;
        }
    }
    @media (max-width: ${viewMax}px){
        padding-left: 20px;
    }
    @media (max-width: ${viewHd}px){
        .contentWidth{
            .content{
                p{
                    font-size: 24px;
                    margin: 15px 0px 20px 0px;
                }
            }
        }
    }
    @media (max-width: ${viewHdSmall}px){
        .contentWidth{
            .content{
                h2{
                    font-size: 26px;
                }
                p{
                    font-size: 20px;
                    margin-right: 80px;
                }
                .formBox{
                    min-height: 154px;
                    width: auto;
                    max-width: 710px;
                    .navMain{
                        min-width: 300px;
                        li a{
                            padding: 15px 20px;
                            font-size: 16px;
                            &:after{
                                right: -21px;
                                border-width: 26px 0 23px 21px;
                            }
                        }
                    }
                    .contentBox{
                        padding-left: 30px;
                        h3{
                            margin-top: 12px;
                            font-size: 18px;
                        }
                        .shortText{
                            margin: 12px 0px 12px 0px;
                        }
                        ul li a{
                            height: 44px;
                            svg{
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
        .imageBackground{
            width: 30%;
        }
    }
    @media (max-width: ${viewSd}px){
        padding: 20px;
        .contentWidth{
            .content{
                max-width: none;
                margin-top: 10px;
                .formBox{
                    display: block;
                    max-width: none;
                    .navMain{
                        li a{
                            padding: 15px 30px;
                            &:after{
                                display: none;
                            }
                        }
                    }
                    .contentBox{
                        h3{
                            display: none;
                        }
                        .shortText{
                            padding: 25px 0px 25px 0px;
                        }
                        ul{
                            padding-bottom: 20px;
                            li a{
                                height: 52px
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${viewMobile}px){
        .contentWidth{
            .content{
                .formBox{
                    .contentBox{
                        ul{
                            display: block;
                            &:after{
                                content: '';
                                clear: both;
                                display: block;
                            }
                            li{
                                display: block;
                                float: left;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
        .imageBackground{
            display: none;
        }
    }
`;	

export default UnderHeaderStyle;