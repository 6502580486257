import styled from 'styled-components';
import { viewMobile, viewSd } from '../../../actions/variables';

const Step3Style = styled.div`
    position: relative;
    .categoryImage{
        position: absolute;
        top: 20px;
        left: 56px;
        z-index: 56;
        width: 300px;
        height: 200px;
        background-color: white;
        padding: 20px;
        border: 1px solid grey;
        box-shadow: 0px 0px 50px rgba(0,0,0,0.8);
        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
    .typeOfToners{
        position: relative;
        top: -10px;
        display: flex;
        flex-direction: row;
        .left , .right{
            flex: 1;
            color: grey;
            position: relative;
            top: -7px;
            padding: 5px 5px 5px 5px;
            cursor: pointer;
            user-select: none;
            flex-direction: row;
            display: flex;
            svg{
                position: relative;
                top: 3px;
            }
            &.selected{
                color: green;
                font-weight: bold;
                .fill{
                    &:before{
                        content: '';
                        display: block;
                        position: relative;
                        height: 2px;
                        background-color: green;
                        top: 9px;
                    }
                }
            }
            .fill{
                flex: 1;
            }
        }
        .left{
            text-align: right;
            .fill:before{
                margin-right: 5px;
            }
        }
        .right{
            .fill:before{
                margin-left: 5px;
            }
            svg{
                margin-right: 5px;
            }
        }
        .switch{
            position: relative;
            top: -5px;
            margin: 0px 10px;
        }
    }
    table{
        width: 100%;
        padding: 0px;
        border-collapse: collapse;
        thead{
            tr{
                background-color: var(--color-base-dark);
                border: 1px solid var(--color-base-dark);
                td{
                    color: white;
                    font-weight: 300;
                }
            }
        }
        tbody{
            tr{
                background-color: #f3f3f3;
                td{
                    label{
                        display: none;
                    }
                    border: 1px solid lightgray;
                    select , input{
                        display: block;
                        width: 100%;
                        border-radius: 0px;
                        outline: 0px;
                        padding: 10px 10px;
                        background-color: white;
                        border: 1px solid lightgray;
                        font-size: 14px;
                        color: grey;
                        background-color: #f9f9f9;
                        transition: all 0.3s;
                        &:focus{
                            border-color: grey;
                            background-color: white;
                        }
                    }
                    button{
                        position: relative;
                        border-radius: 0px;
                        background-color: #f9f9f9;
                        border: 1px solid lightgray;
                        outline: 0px;
                        padding: 8px;
                        color: grey;
                        cursor: pointer;
                        transition: 0.3s;
                        font-size: 13px;
                        display: block;
                        width: 100%;
                        flex: 1;
                        svg{
                            position: relative;
                            top: 3px;
                        }
                        &:hover{
                            background-color: var(--color-base-dark);
                            color: white;
                            border-color: black;
                        }
                        &.remove{
                            &:hover{
                                background-color: red;
                                svg path{
                                    fill: white;
                                }
                            }
                        }
                        &:disabled , &:disabled:hover{
                            background-color: #d8d8d8;
                            border: 1px solid #bbbaba;
                            cursor: default;
                            svg path{
                                fill: grey;
                            }
                        }
                    }
                    .tdButtons{
                        display: flex;
                        flex-direction: row;
                    }
                    .dropZone{
                        flex: 1;
                    }
                    .image{
                        position: relative;
                        top: 5px;
                        max-height: 35px;
                    }
                    span.cat{
                        display: inline-block;
                        margin-left: 5px;
                        font-weight: 500;
                        color: green;
                    }
                    &.error{
                        select,input{
                            border: 1px solid #fda4a4;
                            background-color: #ffe1e1;
                        }
                        svg path{
                            fill: red;
                        }
                    }
                }
                &:nth-child(even){
                    background-color: white;
                }
            }
        }
        tr{
            td{
                padding: 4px 6px;
                &.quantity{
                    width: 90px;
                }
            }
        }
    }
    button.add{
        display: inline-block;
        margin-top: 10px;
        position: relative;
        border-radius: 0px;
        background-color: var(--color-lime-dark);
        border: 1px solid lightgray;
        outline: 0px;
        padding: 8px;
        color: white;
        cursor: pointer;
        transition: 0.3s;
        svg{
            position: relative;
            top: 2px;
        }
        &:hover{
            background-color: var(--color-base-dark);
            border-color: black;
        }
    }
    @media (max-width: ${viewSd}px){
        table{
            display: block;
            thead{
                display: none;
            }
            tbody{
                display: block;
                tr{
                    display: block;
                    td{
                        label{
                            display: block;
                            color: grey;
                            text-transform: uppercase;
                            font-size: 12px;
                        }
                        display: block;
                        padding: 0px 0px 10px 0px;
                        background-color: transparent;
                        border: 0px;
                    }
                    &:nth-child(even){
                        background-color: transparent;
                    }
                }
            }
        }
    }
    @media (max-width: ${viewMobile}px){
        .typeOfToners{
            top: 10px;
            margin-bottom: 15px;
        }
    }
`;	

export default Step3Style;