import styled from 'styled-components';
import { viewSd } from '../../actions/variables';

const LogosStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    .contentWidth{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 100px 0px;
        .firmLogo{
            padding: 0px 20px;
            img{
                width: 100%;
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .contentWidth{
            padding: 50px 0px;
            /* display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px; */
            flex-wrap: wrap;
            .firmLogo{
                margin-bottom: 20px;
            }
        }
    }
`;	

export default LogosStyle;