import styled from 'styled-components';

const FormBoxStyle = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: white;
    width: 854px;
    min-height: 212px;
    ul.navMain{
        display: block;
        list-style: none;
        margin: 0px;
        padding: 0px;
        min-width: 371px;
        li{
            display: block;
            margin: 3px 0px 0px 0px;
            padding: 0px;
            a{
                display: block;
                text-decoration: none;
                background-color: #f3f3f3;
                padding: 26px 30px;
                text-transform: uppercase;
                color: black;
                font-size: 19px;
                font-weight: 700;
                /* transition: all 0.3s; */
                position: relative;
                &.selected{
                    background-color: var(--color-base-dark);
                    color: white;
                    &:after{
                        position: absolute;
                        right: -39px;
                        top: 0px;
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 39px 0 36px 39px;
                        transition: all 0.3s;
                        border-color: transparent transparent transparent var(--color-base-dark);
                    }
                }
            }
            &:nth-child(1){
                margin-top: 0px;
            }
        }
    }
    .contentBox{
        padding-left: 70px;
        padding-top: 7px;
        flex-direction: column;
        display: flex;
        h3{
            color: var(--text-color-grey);
            margin: 27px 0px 0px 0px;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 600;
        }
        .shortText{
            font-size: 16px;
            font-weight: 400;
            color: var(--text-color-grey);
            margin: 25px 0px 25px 0px;
            flex: 1;
        }
        ul{
            display: block;
            list-style: none;
            margin: 0px 0px 0px 0px;
            padding: 0px;
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            li{
                display: flex;
                padding: 0px;
                margin: 0px 10px 0px 0px;
                position: relative;
                a{
                    border-radius: 30px;
                    background-color: var(--color-base-dark);
                    color: white;
                    /* font-size: 12px; */
                    padding: 13px 13px;
                    text-decoration: none;
                    font-weight: 700;
                    display: block;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    /* align-content: center; */
                    height: 52px;
                    justify-content: center;
                    svg{
                        font-size: 28px;
                    }
                    &:hover{
                        background-color: var(--color-base);
                    }
                }
                &.form{
                    a{
                        background-color: var(--color-lime);
                        &:hover{
                            background-color: black;
                        }
                    }
                }
                &.whatsup , &.messanger{
                    a{
                        padding: 0px;
                        background-color: transparent;
                        svg path{
                            color: #066791;
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
                &.form-1{
                    a{
                        padding: 13px 23px;
                    }
                }
            }
        }
        &.contentBox-2 , &.contentBox-3 , &.contentBox-4{
            padding-right: 20px;
            ul{
                display: block;
                li.form{
                    display: block;
                    a{
                        width: auto;
                        background-color: var(--color-lime);
                        border-radius: 15px;
                        font-size: 24px;
                        &:hover{
                            background-color: black;
                        }
                    }
                    
                }
            }
        }
    }
`;	

export default FormBoxStyle;