import React,{ useEffect, useState } from 'react';
import OpinionsStyle from './OpinionsStyle';
import img1 from '../../assets/images/clients.png';
import img2 from '../../assets/images/clients2.png';
import parse from 'html-react-parser';

import api,{ urlPhotos } from '../../actions/api';
import { randomInteger } from '../../actions/functions';

import starImage from '../../assets/images/star1.png';
import starImageEmpty from '../../assets/images/star3.png';

const Opinions = () => {

    const [data,setData] = useState([]);

    // const data = [
    //     { 
    //         image: img1,
    //         name: 'Mariusz',
    //         date: '2019-03,12',
    //         place: 'Borki',
    //         opinion: 'Szybki kontakt. Super współpraca.',
    //         rate: 5
    //     },
    //     { 
    //         image: img2,
    //         name: 'Zenon',
    //         date: '2020-01,23',
    //         place: 'Rzeszów',
    //         opinion: 'Szybka transakcja, polecam.',
    //         rate: 5
    //     },
    //     { 
    //         image: img1,
    //         name: 'Irena',
    //         date: '2019-07-07',
    //         place: 'Warszawa',
    //         opinion: 'Bezproblemowa transakcja, wysłałem w czwartek, w następnym tygodniu już była kasa.',
    //         rate: 4
    //     },
    //     { 
    //         image: img2,
    //         name: 'Piotr',
    //         date: '2021-01,30',
    //         place: 'Głuszyce',
    //         opinion: 'Polecam. Szybko i sprawnie.',
    //         rate: 5
    //     },
    // ]

    const randomPhoto = () => {
        if (randomInteger(1,10)<=5) return img1;
        else return img2;
    }

    useEffect(() => {
        api('getOpinions','',{}, r => {
            if (r.result){
                setData(r.data.opinions.map(el => {
                    if (el.photo === '') el.photo =  randomPhoto();
                    else el.photo = urlPhotos+el.photo
                    return el;
                }));
            }
        });
    },[]);

    const makeStars = rate => {
        let star = '';
        for(let i=1;i<=5;i++){
            star += '<span class="star'+(rate>=i ? ' selected' : '')+'"></span>';
        }
        return parse(star);
    } 

    return (
        <OpinionsStyle starImage={starImage} starImageEmpty={starImageEmpty}>
            <div className="contentWidth">
                <header>Wybrane opinie naszych klientów</header>
                <div className="opinions">
                    {data.map((obj,index) => (
                        <div className="opinion" key={`key_${index}`}>
                            <div className="image" style={{ backgroundImage: 'url('+obj.photo+')' }}>
                                <img src={obj.photo} alt="Sprzedaj toner" />
                            </div>
                            <div className="mainContent">
                                <div className="header">
                                    <label>{obj.name}</label>
                                    <div className="date">{obj.date}</div>
                                    <div className="place">{obj.place}</div>
                                </div>
                                <p>{obj.opinion}</p>
                                <div className="stars">
                                    {makeStars(obj.rate)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </OpinionsStyle>
    );
}
export default Opinions;