import React , { useState } from 'react';
import FaqStyle from './FaqStyle';
import parse from 'html-react-parser';

const Faq = ({ refFaq }) => {

    const [sel,setSel] = useState(0);

    const data = [
        { 
            title: 'Jakie tonery skupujemy',
            answer: '<strong>PEŁNE</strong> — oryginalne, nieuszkodzone, zamknięte w fabrycznym opakowaniu (więcej informacji znajdą Państwo w sekcji "kategorie tonerów").<br /> <strong>PUSTE</strong> — produkty oryginalne, nigdy nie regenerowane, bez uszkodzeń mechanicznych, zarysowań, wgnieceń czy śladów zabrudzeń.',
        },
        { 
            title: 'Jakich tonerów nie skupujemy?',
            answer: 'Nie przyjmujemy zasobników nieoryginalnych i zamienników (tzw. Chińskich ). Nie zajmujemy się również skupem produktów uszkodzonych mechanicznie.',
        },
        { 
            title: 'Tonery jakich producentów kupujemy?',
            answer: 'Skupujemy oryginalne kasety akcesoria następujących marek: HP, Canon, Brother, OKI, Ricoh, NRG, Samsung, Panasonic, Lexmark, Toshiba, Sharp, Xerox.',
        },
        { 
            title: 'Jak powinienem spakować towar w przypadku wysyłki kurierem?',
            answer: 'Tonery przeznaczone do sprzedaży powinny być spakowane w możliwie bezpieczny sposób, tak aby nie zostały uszkodzone podczas transportu. To bardzo ważne, również ze względu na zaklasyfikowanie przesłanego produktu do konkretnej kategorii.',
        },
        { 
            title: 'Jeśli na opakowaniu będą napisy czy też taśmy czy powinienem je usunąć we własnym zakresie?',
            answer: 'Absolutnie nie! Usuwanie niedoskonałości opakowania na własną rękę może spowodować obniżenie wartości sprzedawanych produktów. Prosimy o przesyłanie zbiorników w takim stanie w jakim je Państwo posiadają. Warto zaufać profesjonalistom.',
        },
        { 
            title: 'Co się dzieje po wypełnieniu formularza?',
            answer: 'W przeciągu 12 godzin zostaniesz poinformowany o wartości sprzedawanych tonerów i otrzymasz propozycję oferty do zaakceptowania.',
        },
        { 
            title: 'Kto i kiedy odbierze ode mnie tonery?',
            answer: 'Tonery odbierze od Ciebie kurier, ewentualnie nasz kierowca (Warszawa i okolice) w terminie określonym w formularzu. Prosimy o podanie danych kontaktowych, aby możliwe było sprawne przekazanie przesyłki.',
        },
        { 
            title: 'Kiedy i w jakiej formie otrzymam zapłatę?',
            answer: `Po weryfikacji tonerów pod względem ich zgodności z wypełnionym przez Ciebie formularzem pieniądze przekazujemy na dwa sposoby:
            <ul>
                <li>przelewem na wskazane wcześniej konto</li>
                <li>gotówką</li>
            </ul>
            W przypadku dodatkowych pytań lub wątpliwości zapraszamy do kontaktu z naszą infolinią.`,
        },
    ]

    const handleClick = (event,index) => {
        event.preventDefault();
        setSel(index);
    }

    return (
        <FaqStyle>
            <div className="contentWidth" ref={refFaq}>
                <header>FAQ</header>
                <div className="faqs">
                    {data.map((obj,index) => (
                        <div className={"faq faq-"+index+(sel === index ? ' selected' : '')} key={`faq_${index}`} onClick={event => handleClick(event,index)}>
                            <h4>{`${index+1}. ${obj.title}`}</h4>
                            <div className="answer">{parse(obj.answer)}</div>
                        </div>
                    ))}
                </div>
            </div>
        </FaqStyle>
    );
}
export default Faq;