import React from 'react';
import UnderHeaderStyle from './UnderHeaderStyle';
import FormBox from './FormBox/FormBox';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

const UnderHeader = ({ setShowForm , refForms }) => {

    const dataQl = useStaticQuery(graphql`
        query {
            allFile(filter: {publicURL: {regex: "/.*top-background.jpg.*/"}}) {
                edges {
                node {
                    publicURL
                    childImageSharp {
                    fluid(maxWidth:800, maxHeight:442, quality: 70) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                    }
                }
                }
            }
        }
    `)

    // console.log('dataQL: ',dataQl);

    return (
        <UnderHeaderStyle>
            <div className="contentWidth">

                 <div className="content">
                    <h2>Masz niepotrzebne, niewykorzystane tonery, które tylko Ci zalegają?</h2>
                    <p>
                        Dobrze trafiłeś, wypełnij formularz i ciesz się z odzyskania pieniędzy. Bądź EKO, chroń środowisko.
                    </p>
                    <FormBox className="formBox" setShowForm={setShowForm} refForms={refForms} />
                 </div>
            </div>
            <BackgroundImage fluid={dataQl.allFile.edges[0].node.childImageSharp.fluid} className="imageBackground" imgStyle={{ backgroundPosition: 'top left' }}></BackgroundImage>
        </UnderHeaderStyle>
    );
}
export default UnderHeader;