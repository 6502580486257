import styled from 'styled-components';
import { viewHdSmall , viewSd, viewMobile } from '../../actions/variables';

const FormUtylizeStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,0.7);
    .overlay{
        width: 900px;
        min-height: 300px;
        background-color: #f7f7f7;
        overflow: hidden;
        header{
            color: grey;
            padding: 20px 20px;
            background-color: var(--color-base-dark);
            color: white;
            margin-bottom: 20px;
            border-bottom: 1px solid grey;
            position: relative;
            span{
                color: var(--color-lime);
                font-weight: 500;
            }
            .stepsDots{
                position: absolute;
                top: 20px;
                right: 20px;
            }
            .errComm{
                position: absolute;
                color: red;
                text-align: center;
                width: 100%;
                left: 0px;
                bottom: -30px;
            }
        }
        .steps{
            padding: 20px;
            flex: 1;
            position: relative;
            min-height: 340px;
            .formRight{
                padding-left: 15px;
                flex: 1;
            }
            label{
                display: block;
                font-size: 14px;
                color: grey;
                margin-bottom: 5px;
                transition: all 0.3s;
                span{
                    color: #bbbbbb;
                    float: right;
                    font-style: italic;
                    font-size: 12px;
                }
                &.disabled label{
                    color: #8e8e8e;
                }
            }
            .form{
                flex: 1;
                display: flex;
                flex-direction: row;
                .cell{
                    margin-bottom: 10px;
                    input , select, textarea{
                        display: block;
                        width: 100%;
                        border: 1px solid #dadada;
                        padding: 15px 20px;
                        color: grey;
                        background-color: #efefef;
                        outline: 0px;
                        color: black;
                        transition: all 0.3s;
                        &:focus{
                            border-color: grey;
                            background-color: white;
                        }
                        &:disabled{
                            color: #808080 !important;
                            background-color: #d8d8d8;
                            cursor: not-allowed;
                        }
                    }
                    select{
                        padding: 15px 20px 13px 20px;
                    }
                    textarea{
                        min-height: 132px;
                        max-height: 132px;
                    }
                    &.error{
                        label{
                            color: red;
                        }
                        input{
                            background-color: #ffd3d3;
                            border-color: red;
                        }
                    }
                    &.cell-group{
                        display: flex;
                        flex-direction: row;
                        .grouped{
                            /* padding-top: 1px; */
                            flex: 1;
                            &:nth-child(1){
                                padding-right: 10px;
                            }
                        }
                    }
                    &.last{
                        margin-bottom: 0px;
                    }
                }
                .pictures{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .uploadImages{
                        background-color: #efefef;
                        border: 1px solid grey;
                        flex: 1;
                        position: relative;
                        min-height: 164px;
                        .dropZone{
                            position: absolute;
                            bottom: 0px;
                            right: 0px;
                            width: 100%;
                            button{
                                position: absolute;
                                bottom: 10px;
                                right: 10px;
                            }
                        }
                        .photos{
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 10px;
                            padding: 15px;
                            .photo{
                                text-align: center;
                                height: 80px;
                                overflow: hidden;
                                border: 1px solid grey;
                                position: relative;
                                .remove{
                                    position: absolute;
                                    top: 5px;
                                    right: 5px;
                                    &:hover{
                                        svg path{
                                            fill: black;
                                        }
                                    }
                                }
                                img{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .buttons{
            flex: 1;
            max-height: 60px;
            height: 60px;
            /* height: 50px; */
            background-color: grey;
            padding: 11px 20px;
            text-align: right;
            button{
                padding: 8px 13px;
                border-radius: 0px;
                outline: 0px;
                border: 1px solid grey;
                background-color: #e0e0e0;
                position: relative;
                cursor: pointer;
                margin-left: 10px;
                svg{
                    position: relative;
                    top: 2px;
                    path{
                        fill: grey;
                    }
                }
                &:hover{
                    background-color: var(--color-base-dark);
                    color: white;
                    svg path{
                        fill: white;
                    }
                }
                &.close{
                    float: left;
                    background-color: black;
                    color: white;
                    margin-left: 0px;
                    svg path{
                        fill: white;
                    }
                    &:hover{
                        background-color: var(--color-base-dark);
                    }
                }
                &.unlock , &.saveProfile{
                    float: left;
                    background-color: green;
                    color: white;
                    svg path{
                        fill: white;
                    }
                    &:hover{
                        background-color: #004600;
                    }
                }
                &.editCancel{
                    float: left;
                }
                &.final{
                    background-color: var(--color-lime-dark);
                    color: white;
                    /* margin-left: 10px; */
                    border: 1px solid black;
                    &:hover{
                        background-color: black;
                    }
                    svg{
                        path{
                            fill: white;
                        }
                    }
                    &.disabled{
                        background-color: #808080;
                        border-color: #676767;
                        color: #4a4848;
                        cursor: default;
                        svg path{
                            fill: #4a4848;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${viewHdSmall}px){
        z-index: 820;
        .overlay{
            width: 100%;
            height: 100%;
            min-height: 100%;
            .steps{
                min-height: calc(100% - 140px);
                max-height: calc(100% - 140px);
                overflow: auto;
                .form{
                    margin: 0px 0px 30px 0px;
                    display: block;
                    .cell.last{
                        padding-bottom: 30px;
                    }
                }
                .formRight{
                    padding-left: 0px;
                }
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .overlay{
            width: 100%;
            height: 100%;
            min-height: 100%;
            .steps{
                .step{
                    &.step-2{
                        table{
                            width: 700px;
                            overflow-x: auto;
                        }
                    }
                    &.step-1 .body{
                        display: block;
                        .cell{
                            margin-bottom: 10px;
                        }
                    }
                    &.step-3{
                        .layerSwitchContent{
                            top: 0px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${viewMobile}px){
        .overlay{
            header .stepsDots{
                display: none;
            }
            .steps{
                .form{
                    
                }
                padding-top: 0px;
                .cell-group{
                    display: block !important;
                    .grouped{
                        
                    }
                }
                .cell{
                    margin-left: 0px !important;
                    max-width: 100% !important;
                }
            }
            .buttons{
                button{
                    &.unlock{
                        span{
                            display: none;
                        }
                    }
                }
            }
        }
    }
`;	

export default FormUtylizeStyle;