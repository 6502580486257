import React from 'react';
import InfoStyle from './InfoStyle';
import { graphql , useStaticQuery } from 'gatsby';
import Image from "gatsby-image"

const Info = () => {

    const dataQl = useStaticQuery(graphql`
    query {
        allFile(filter: {publicURL: {regex: "/.*sprzedaj-toner-www-full-page-02_21.*/"}}) {
            edges {
              node {
                publicURL
                childImageSharp {
                  fluid(maxWidth:624, maxHeight:479, quality: 80) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
    }
  `)

    return (
        <InfoStyle>
            <div className="contentWidth">
                <div className="toner">
                    <Image fluid={dataQl.allFile.edges[0].node.childImageSharp.fluid} alt="Sprzedaj toner" title="Sprzedaj toner" />
                </div>
                <div className="tonerContent">
                    <h2>Skup pełnych oryginalnych tonerów w Warszawie</h2>
                    <h3>Internetowy skup tonerów wszystkich producentów na rynku.</h3>

                    <p>Wieloletnie doświadczenie i wiedza na temat tych produktów pozwalają nam na zapewnienie profesjonalnej, szybkiej obsługi oraz wsparcia technicznego dla naszych Klientów. Skup tonerów pełnych to sposób na odzyskanie pieniędzy za niewykorzystane, zalegające w magazynie materiały eksploatacyjne.</p>
                    <p>Całą procedurę sprzedaży mogą Państwo bez problemu przeprowadzić online. Prowadzimy skup tonerów niewykorzystanych wszytkich marek, m.in. HP, Canon, Minolta czy OKI – należy wypełnić odpowiedni formularz, w którym zawrzeć trzeba dane kontaktowe oraz informacje o produkcie – w odpowiedzi my przekazujemy naszą ofertę. Skup tonerów niewykorzystanych to rozwiązanie korzystne dla Państwa nie tylko z finansowego punktu widzenia. Każda osoba sprzedająca te produkty naszej firmie dokłada również swoją cegiełkę do ochrony środowiska.</p>
                    <p>Dbamy o jak najatrakcyjniejsze ceny, oferując także liczne promocje. Przy odbieraniu odpadów gwarantujemy nie tylko kompleksową obsługę.</p>

                </div>
            </div>
        </InfoStyle>
    );
}
export default Info;