import React from 'react';
import { graphql , useStaticQuery } from 'gatsby';
import Image from "gatsby-image"
import CategoriesStyle from './CategoriesStyle';

const Categories = ({ refCategories }) => {

    const dataQl = useStaticQuery(graphql`
    query {
        allFile(sort: {fields: name, order: ASC},filter: {publicURL: {regex: "/.*kategoria.*/"}}) {
            edges {
              node {
                publicURL
                childImageSharp {
                  fixed(width: 200, height: 150, quality: 80) {
                    ...GatsbyImageSharpFixed_tracedSVG
                  }
                }
              }
            }
          }
    }
  `)

    const data = [
        {
            letter: 'A',
            content: 'Jest to najwyższa klasa produktu. W tej grupie znajdują się oryginalne tonery w opakowaniach, które nigdy nie były otwierane. Co ważne, produkty z kategorii A nie mogą mieć żadnych uszkodzeń.',
            image: dataQl.allFile.edges[0].node.childImageSharp.fixed
        },
        {
            letter: 'B',
            content: 'Znajdują się tutaj produkty oryginalne, jednak z widocznymi lekkimi defektami na opakowaniu. Mogą to być między innymi zadrapania i rysy, lekkie przygniecenia, napisy czy ślady po taśmach. Istotne jest również to, aby pudełko nie było otwierane.',
            image: dataQl.allFile.edges[1].node.childImageSharp.fixed
        },
        {
            letter: 'C',
            content: 'To również kategoria, w której znajdują się wyłącznie produkty oryginalne, natomiast w tym przypadku pudełko może być otwarte. Aby jednak zasobnik był zaliczony do tej grupy, folia, w którą jest opakowany, musi być oryginalnie i szczelnie zamknięta.',
            image: dataQl.allFile.edges[2].node.childImageSharp.fixed
        },
        {
            letter: 'D',
            content: 'Ostatnia kategoria produktów przyjmowanych w naszym skupie tonerów. Produkty muszą być oryginalne, jednak mogą być dostarczone w otwartym pudełku. Dopuszczalna jest również otwarta folia, jednak plomba kasety nie może być w żaden sposób naruszona.',
            image: dataQl.allFile.edges[3].node.childImageSharp.fixed
        },
    ]

    return (
        <CategoriesStyle>
            <div className="contentWidth" ref={refCategories}>
                {data.map((obj,index) => (
                    <div className="category" key={`category_${index}`}>
                        <div className="main">
                            <label>{obj.letter}</label>
                            <p>{obj.content}</p>
                        </div>
                        <div className="image">
                            <Image fixed={obj.image} alt="Sprzedaj toner" title="Sprzedaj toner" />
                        </div>
                    </div>
                ))}
            </div>
        </CategoriesStyle>
    );
}

// export const query = graphql`
//   {
//     allFile(filter: {publicURL: {regex: "/.*kategoria.*/"}}) {
//         edges {
//           node {
//             publicURL
//             childImageSharp {
//               fixed(width: 200, height: 150, quality: 80) {
//                 ...GatsbyImageSharpFixed_tracedSVG
//               }
//             }
//           }
//         }
//       }
//   }
// `;

export default Categories;