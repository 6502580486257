import React from 'react';
import StepsStyle from './StepsStyle';
import Svg from '../../components/Svgs/Svgs';

const Steps = ({ setShowForm }) => {

    const data = [
        {
            name: 'Krok 1',
            header: 'Wypełnij formularz',
            content: ['Podaj nam możliwie najwięcej informacji: zdjęcie tonera i jego datę produkcji'],
            button: { label: 'Przejdź do formularza', action: '' }
        },
        {
            name: 'Krok 2',
            header: 'Otrzymaj wycenę',
            content: [
                'Po jej akceptacji prześlemy Ci odpowiednie dokumenty wraz z listem przewozowym do transportu',
                'Jedyne co musisz zrobić to bezpiecznie spakować tonery'
            ],
        },
        {
            name: 'Krok 3',
            header: 'Transport',
            content: [
                'Spakuj tonery. Dołącz umowę do paczki a list przewozowy przyklej na karton',
                'Kurier odbierze przesyłkę w dogodnym dla Ciebie terminie'
            ],
        },
        {
            name: 'Krok 4',
            header: 'Płatność',
            content: ['Po weryfikacji tonerów niezwłocznie przelewamy umówioną kwotę na wskazany numer konta bankowego'],
        },
    ]

    const handleClick = (event) => {
        event.preventDefault();
        setShowForm(1);
    }

    return (
        <StepsStyle>
            <div className="contentWidth">
                {data.map((obj,index) => (
                    <div className={`step step-${index}`} key={`step_${index}`}>
                        <label>{obj.name}</label>
                        <header>{obj.header}</header>
                        {obj.content.map((el,index2) => <p key={`step_${index}_${index2}`}>{el}</p>)}
                        {obj.button!==undefined &&
                            <div className="button">
                                <a href={obj.button.action} onClick={handleClick}>{obj.button.label}</a>
                            </div>
                        }
                        {index<3 &&
                            <Svg name="arrow-right" color="#e8e8e8" />
                        }
                        
                    </div>
                ))}
            </div>
        </StepsStyle>
    );
}
export default Steps;