import React , { useState, useEffect } from 'react';
import FormStyle from './FormValuatingStyle';
import { FaTimes , FaRegEnvelope } from 'react-icons/fa';
import api, { urlPhotos } from '../../actions/api';
import { reactLocalStorage } from 'reactjs-localstorage';
import { isEmpty, validateEmail } from '../../actions/functions';
import { useClickOutside } from 'react-click-outside-hook';
import { firsLetter } from '../../actions/functions';
import Upload from '../../components/Upload/Upload';
import Switch from "react-switch";
import { countrys , defaultCountry } from '../../actions/variables';
import InputMask from 'react-input-mask';

let saveDataStorage = null;
const maxPhotos = 6;
const isBrowser = typeof window !== "undefined"
const lang = isBrowser ? (navigator.language || navigator.userLanguage) : '';

const Form = ({ setShowForm , showForm , Notify , userData , userToken , setDataComment }) => {

    const [name,setName] = useState('');
    const [surname,setSurname] = useState('');
    const [phone,setPhone] = useState('048');
    const [email,setEmail] = useState('');
    const [tonersFull,setTonersFull] = useState(true);
    const [photos,setPhotos] = useState([]);
    const [content,setContent] = useState('');
    const [country,setCountry] = useState('');
    const [mask,setMask] = useState('+99-999 999 999');

    const [refOverlay, hasClickedOutsideOverlay] = useClickOutside();
    const [errComm,setErrComm] = useState('');
    const [sending,setSending] = useState(false);
    const [loadingPhotos,setLoadingPhotos] = useState(false);
    const [allowSend,setAllowSend] = useState(false);

    const [errName,setErrName] = useState(false);
    const [errSurname,setErrSurname] = useState(false);
    const [errPhone,setErrPhone] = useState(false);
    const [errEmail,setErrEmail] = useState(false);

    useEffect(() => {
        const dataStorage = reactLocalStorage.getObject('data_form_'+showForm);
        if (!isEmpty(dataStorage)){
            setName(dataStorage.name);
            setSurname(dataStorage.surname);
            setPhone(dataStorage.phone);
            setEmail(dataStorage.email);
            setTonersFull(dataStorage.tonersFull)
            setPhotos(dataStorage?.photos!==undefined ? dataStorage?.photos : [])
            setContent(dataStorage.content)
        } else {
            if (userData !== null){
                setName(userData.name);
                setSurname(userData.surname);
                setEmail(userData.email);
                setPhone(userData.phone);
            }
        }

        if (country === ''){
            let found = false;
            countrys.forEach(el => {
                if (el.prefix.includes(lang.toUpperCase())){
                    setCountry(el.name);
                    found = true;
                }
            })
            if (!found){
                setCountry(defaultCountry);
            }
        }
    },[]);

    useEffect(() => {
        if (country!=''){
            countrys.forEach(el => {
                if (el.name === country){
                    const lengthPrefix = mask.split('-')[0].length;
                    setPhone(phone.replace(phone.substr(0,lengthPrefix),'+'+el.prefixPhone));
                    setMask(el.mask);
                }
            })
        }
    },[country]);

    useEffect(() => {
        if (hasClickedOutsideOverlay){
            setShowForm(false)
        }
    },[hasClickedOutsideOverlay]);

    useEffect(() => {
        if (errComm!==''){
            setTimeout(() => {
                setErrComm('');
            },3000);
        }
    },[errComm]);

    useEffect(() => {
        if (saveDataStorage) clearTimeout(saveDataStorage);
        saveDataStorage = setTimeout(() => {
            reactLocalStorage.setObject('data_form_'+showForm,{ name,surname,phone,email,tonersFull,content,photos })
        },3000);

        // const tempPhone = phone.replaceAll('_','').replaceAll(' ','');
        
        if (name.length>1 && validateEmail(email) && photos.length>0 && phone.indexOf('_') < 0){
            setAllowSend(true);
        } else {
            setAllowSend(false);
        }

    },[name,surname,phone,email,tonersFull,content,photos]);

    const clearData = () => {
        clearTimeout(saveDataStorage);
        setPhotos([]);
        setName('');
        setSurname('');
        setPhone('');
        setEmail('');
        setContent('');
        reactLocalStorage.remove('data_form_'+showForm);
    }

    const send = () => {
        // console.log('sending ...');
        setSending(true);

        const sendData = {
            name,
            surname,
            phone,
            email,
            tonersFull: Number(tonersFull),
            photos,
            content
        }

        api('saveFormQuick',userToken,sendData,r => {
            setSending(false);
            if (r.result){
                setShowForm(false);
                setDataComment({ header: 'Formularz szybkiej wyceny', buttonClose: true, content: 'Dziękujemy za wypełnienie formularza, został on wysłany i będzie dalej procedowany.' });
                // Notify('Formularz szybkiej wyceny','Formularz został wysłany');
                clearData();
            } else {
                Notify('Formularz szybkiej wyceny','Błąd wysyłania formularza, proszę sprawdzić poprawność danych w formularzu','error');
            }
        });
    }

    const addPhoto = photo => {
        setPhotos(v => [...photos,...[photo]]);
    }

    const handleRemovePhoto = (ev,photo) => {
        ev.preventDefault();
        setLoadingPhotos(true);
        api('removePhoto','',{ photo },r => {
            setLoadingPhotos(false);
            setPhotos(photos.filter(ph => ph!==photo));

            const dataStorageString = reactLocalStorage.get('data_form_'+showForm);
            if (dataStorageString){
                const dataStorage = JSON.parse(dataStorageString);
                // console.log(typeof dataStorage,dataStorage);
                if (dataStorage?.photos){
                    const photosStorage =  dataStorage.photos.filter(ph => ph != photo);
                    reactLocalStorage.setObject({...dataStorage,...{ photos: photosStorage }});
                }
            }
            

        });
    }

    const getMultiFiles = result => {
        const data = [...photos];
        let counter = photos.length;
        result.forEach(photo => {
            counter++;
            if (counter<=maxPhotos) data.push(photo);
        });

        setPhotos(data);
    }

    return (
        <FormStyle>
            
            <div className={"overlay"+(sending ? ' loadingLayer' : '')} ref={refOverlay}>
                <header>
                    {'Formularz szybkiej wyceny'}
                    {/* <Steps current={step} maxSteps={maxSteps} classStyle="stepsDots" /> */}
                    {errComm !== '' && <div className="errComm">{errComm}</div>}
                </header>

                <div className="steps">
                    <div className="form">
                        <div className={"cell cell-group"}>

                            <div className={"cell grouped"+(errName ? ' error' : '')}>
                                <label htmlFor="name">Imię *</label>
                                <input id="name" value={name} onChange={v => setName(firsLetter(v.target.value))} onFocus={() => setErrName(false)} />
                            </div>
                            <div className={"cell grouped"+(errSurname ? ' error' : '')}>
                                <label htmlFor="surname">Nazwisko</label>
                                <input id="surname" value={surname} onChange={v => setSurname(firsLetter(v.target.value))} onFocus={() => setErrSurname(false)} />
                            </div>
                            
                        </div>
                        <div className={"cell cell-group"}>
                            <div className={"cell grouped"+(errPhone ? ' error' : '')}>
                                <label htmlFor="phone">Telefon *</label>
                                <InputMask id="house" mask={mask} value={phone} onChange={v => setPhone(v.target.value)} />
                            </div>
                            <div className={"cell"}>
                            <label htmlFor="country">Państwo</label>
                            <select onChange={ev => setCountry(ev.target.value)} value={country}>
                                {countrys.map(obj => (
                                    <option key={`option_country_${obj.prefixPhone}`} value={obj.name}>
                                        {obj.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        </div>
                        <div className={"cell"+(errEmail ? ' error' : '')}>
                            <label htmlFor="email">E-mail *</label>
                            <input id="email" value={email} onChange={v => setEmail(v.target.value)} onFocus={() => setErrEmail(false)} />
                        </div>
                        <div className={"cell last"+(errEmail ? ' error' : '')}>
                            <label htmlFor="content">Dodatkowe informacje</label>
                            <textarea id="content" value={content} onChange={v => setContent(v.target.value)} />
                        </div>
                    </div>
                    <div className={"pictures"+(loadingPhotos ? ' loadingLayer' : '')}>
                        <label>Dodaj zdjęcia tonerów, maksymalnie 6 *</label>
                        <div className="uploadImages">
                            {photos.length>0 &&
                                <div className="photos">
                                    {photos.map((img,index) => (
                                        <div className="photo" key={`photo_${index}`}>
                                            <a href="" className="remove" onClick={ev => handleRemovePhoto(ev,img)} title="Usuń zdjęcie">
                                                <FaTimes color="red" />
                                            </a>
                                            <img src={urlPhotos+'small/'+img} />
                                        </div>
                                    ))}
                                </div>
                            }
                            {photos.length<maxPhotos &&
                                <Upload
                                    url="addPhoto"
                                    maxFiles={6}
                                    beforeAction={() => setLoadingPhotos(true)}
                                    afterAction={() => setLoadingPhotos(false)}
                                    successAction={result => getMultiFiles(result)}
                                    Notify={Notify}
                                />
                            }
                        </div>
                        <br />
                        <label>* Pola wymagane</label>
                        <hr />
                        <label>Określ czy tonery są pełne czy też puste</label>
                        <div className="tonersFull">
                            <div className={"switchLine"+(tonersFull ? ' selected' : '')}>
                                <Switch checked={tonersFull} onChange={() => setTonersFull(true)} /> <span onClick={() => setTonersFull(true)}>- Pełne</span>
                            </div>
                            <div className={"switchLine"+(!tonersFull ? ' selected' : '')}>
                                <Switch checked={!tonersFull} onChange={() => setTonersFull(false)} /> <span onClick={() => setTonersFull(false)}>- Puste</span>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="buttons">
                    <button className={"final"+(!allowSend ? ' disabled' : '')} disabled={!allowSend} onClick={() => send()}>Wyślij <FaRegEnvelope /> </button>
                    <button className="close" onClick={() => setShowForm(false)}><FaTimes /> Zamknij</button>
                </div>
            </div>
        </FormStyle>
    );
}
export default Form;