import React , { useState, useEffect, useRef } from 'react';

import { FaAt , FaComment , FaWhatsapp as Whatsup , FaFacebookMessenger } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import useMedia from 'use-media';

import FormBoxStyle from './FormBoxStyle';
import { emailContact , viewSd } from '../../../actions/variables';

const FormBox = (props) => {

    const refDown = useRef();
    const [option,setOption] = useState(1);
    const [title,setTitle] = useState('');
    const [content,setContent] = useState('');
    const isResponsive = useMedia({maxWidth: viewSd+'px'});

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    useEffect(() => {
        if (option === 1) {
            setTitle('Formularz szybkiej wyceny');
            setContent('Wyślij nam zdjęcie materiałów eksploatacyjnych do szybkiej wyceny. Możesz to uczynić przez:');
        } else if (option === 2){
            setTitle('Formularz sprzedaży');
            setContent('Wypełnij jeśli chcesz sprzedać materiały eksploatacyjne.');
        } else if (option === 3){
            setTitle('Formularz wymiany');
            setContent('Wypełnij jeśli chcesz wymienić swoje tonery na pełne:');
        } else if (option === 4){
            setTitle('Formularz utylizacji');
            setContent('Wypełnij jeśli chcesz zutylizować swoje tonery');
        }
    },[option]);

    const handleClick = (event,opt) => {
        event.preventDefault();
        setOption(opt);
        if (isResponsive){
            setTimeout(() => {
                refDown.current.scrollIntoView({ behavior: 'smooth' });
            },100);
        }
    }

    const handleClickForm = (event) => {
        event.preventDefault();
        // if (option==3) return;
        props.setShowForm(option);
    }

    return (
        <FormBoxStyle {...props} ref={props.refForms} ref={refDown}>
            <ul className="navMain">
                <li><a href="/FormQuick" className={option === 1 ? 'selected' : ''} onMouseOver={event => handleClick(event,1)} onClick={event => handleClick(event,1)}>Formularz szybkiej wyceny</a></li>
                <li><a href="/FormSell" className={option === 2 ? 'selected' : ''} onMouseOver={event => handleClick(event,2)} onClick={event => handleClick(event,2)}>Formularz sprzedaży</a></li>
                <li><a href="/FormExchange" className={option === 3 ? 'selected' : ''} onMouseOver={event => handleClick(event,3)} onClick={event => handleClick(event,3)}>Formularz wymiany</a></li>
                <li><a href="/FormUtilize" className={option === 4 ? 'selected' : ''} onMouseOver={event => handleClick(event,4)} onClick={event => handleClick(event,4)}>Formularz utylizacji</a></li>
            </ul>
            <div className={"contentBox contentBox-"+option}>
                <h3>{title}</h3>
                <div className="shortText">{content}</div>
                <ul>
                    {option === 1 && <li data-tip="Napisz do nas"><a href={"mailto:"+emailContact}><FaAt /></a></li>}
                    {/* {option === 1 && <li data-tip="Napisz do nas na Whatsupie" className="whatsup"><a href={"/whatsup"} title="Whatsup"><Whatsup size={58} /></a></li>} */}
                    {/* {option === 1 && <li data-tip="Napisz do nas na Messangerze" className="messanger"><a href={"/Messanger"} title="Facebook mesanger"><FaFacebookMessenger size={50} /></a></li>} */}
                    {/* {option === 1 && <li data-tip="Napisz do nas na czacie"><a href="/czat"><FaComment /></a></li>} */}
                    <li className={"form form-"+option}><a href={"/Form"} onClick={handleClickForm} className="form">{title}</a></li>
                </ul>
            </div>
            <ReactTooltip place="bottom" type="dark" effect="solid" />
        </FormBoxStyle>
    );
}
export default FormBox;