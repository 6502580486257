import styled from 'styled-components';
import { viewMobile } from '../../actions/variables';

const ComponentStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;

    @keyframes fadeOut{
        0%   { opacity: 1; left: 0px; }
        100% { opacity: 0; display: none; }
    }
    @keyframes hideOut{
        0%   { left: 0px; }
        99% { left: 0%; }
        100% { left: -100%; }
    }
    .overlay{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: 1000;
    }

    .menuMobile{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 350px;
        height: 100%;
        padding: 0px;
        background-color: var(--color-base-dark);
        box-shadow: 0px 0px 30px rgba(0,0,0,0.5);
        border-right: 3px solid rgba(0,0,0,0.2);
        transition: all 0.3s;
        z-index: 1100;
        .logo{
            margin: 0px 0px 0px 0px;
            padding: 40px 30px 30px 30px;
            img{
                max-width: 100%;
            }
        }
        ul{
            display: block;
            margin: 0px;
            padding: 0px;
            list-style: none;
            li{
                display: block;
                margin: 0px;
                padding: 0px;
                a{
                    display: block;
                    padding: 10px 20px 15px 20px;
                    margin: 0px;
                    color: white;
                    text-decoration: none;
                    font-size: 22px;
                    position: relative;
                    background-color: #1a5c82;
                    margin-bottom: 1px;
                    svg{
                        display: inline-block;
                        margin-right: 15px;
                        position: relative;
                        top: 5px;
                        /* left: -10px; */
                        width: 25px;
                        height: 25px;
                        path{
                            fill: #51c0ff;
                        }
                    }
                    &:hover{
                        background-color: #124a6b;
                    }
                }
            }
        }
    }
    &.hideMenu{
        animation: hideOut 0.5s normal forwards ease-in-out;
        .menuMobile{
            left: -380px;
        }
        .overlay{
            animation: fadeOut 0.5s normal forwards ease-in-out;
        }

    }
    &.hideMenuStart{
        display: none;
        opacity: 0;
        left: 0px;
    }
    @media (max-width: ${viewMobile}px){
        .menuMobile{
            width: 70%;
            ul{
                li{
                    a{
                        font-size: 20px;
                        svg{
                            width: 20px;
                            height: 20px;
                            top: 2px;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
`;	

export default ComponentStyle;