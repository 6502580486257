import styled from 'styled-components';
import { viewMax , viewSd } from '../../actions/variables';

const InfoStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    .contentWidth{
        flex-direction: row;
        display: flex;
        .toner{
            padding: 30px 30px 30px 0px;
            text-align: right;
            flex: 1;
        }
        .tonerContent{
            padding: 50px 30px 30px 30px;
            flex: 1;
            h2{
                font-weight: 300;
                font-size: 21px;
                margin: 0px 0px 5px 0px;
            }
            h3{
                font-weight: 700;
                font-size: 33px;
                margin: 0px;
                text-transform: uppercase;
            }
        }
    }
    @media (max-width: ${viewMax}px){
        .contentWidth{
            .toner{
                max-width: 40%;
                img{
                    max-width: 100%;
                }
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .contentWidth{
            display: block;
            .toner{
                padding: 30px 40px;
                max-width: 100%;
                text-align: center;
            }
        }
    }
`;	

export default InfoStyle;