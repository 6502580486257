import React from 'react';
import CreateAccountStyle from './CreateAccountStyle';

const CreateAccount = ({ email, callBack }) => {

    return (
        <CreateAccountStyle>
            <div className="header">
                Przy okazji składania zamówienia możesz założyć u nas konto, dzięki niemu będziesz mógł kolejnym razem nie wypełniać ponownie danych na formularzu.
            </div>
            <div className="question">
                Czy chcesz założyć konto?
            </div>
            <div className="questionButtons">
                <button className="yes" onClick={() => callBack(true)}>Tak</button> <button className="no" onClick={() => callBack(false)}>Nie</button>
            </div>
        </CreateAccountStyle>
    );
}
export default CreateAccount;