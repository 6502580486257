import styled from 'styled-components';
import { viewMax , viewHd , viewSd } from '../../actions/variables';

const StepsStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--color-base-dark);
    .contentWidth{
        display: flex;
        flex-direction: row;
        .step{
            padding: 52px 60px;
            position: relative;
            *{
                color: #e8e8e8;
            }
            &.step-0{
                padding-left: 0px;
            }
            &.step-3{
                padding-right: 0px;
            }
            label{
                display: block;
                font-size: 38px;
                font-weight: 300;
                text-transform: uppercase;
            }
            header{
                font-weight: 700;
                text-transform: uppercase;
                margin: 16px 0px 0px 0px;
                font-size: 24px;
                white-space: nowrap;
            }
            .button{
                a{
                    display: inline-block;
                    background-color: #e8e8e8;
                    border-radius: 20px;
                    padding: 10px 25px;
                    color: var(--color-base-dark);
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 700;
                    &:hover{
                        background-color: black;
                        color: white;
                    }
                }
            }
            svg{
                position: absolute;
                top: calc(50% - 20px);
                right: -10px;
                width: 25px;
            }
            p{
                font-size: 18px;
            }
        }
    }
    @media (max-width: ${viewMax}px){
        .contentWidth{
            padding: 0px 20px;
            .step{
                width: 25%;
                padding: 45px 45px;
                p{
                    font-size: 16px;
                }
            }
        }
    }
    @media (max-width: ${viewHd}px){
        .contentWidth{
            padding: 0px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            .step{
                width: 100%;
                padding: 45px 45px;
                position: relative;
                p{
                    font-size: 16px;
                }
                &.step-0{
                    padding-left: 45px;
                    &:after{
                        position: absolute;
                        bottom: 0px;
                        width: calc(100vw - 100px);
                        height: 2px;
                        background-color: #4eb1dc;
                        content: '';
                    }
                }
                &.step-1{
                    svg{
                        display: none;
                    }
                }
                &.step-3{
                    padding-right: 45px;
                }
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .contentWidth{
            padding: 0px;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px;
            .step{
                padding: 25px;
                &.step-0{
                    padding-left: 25px;
                    &:after{
                        width: calc(100vw - 70px);
                    }   
                }
                &:after{
                    width: calc(100vw - 70px) !important;
                } 
                svg{
                    display: none;
                }
                &:after{
                    position: absolute;
                    bottom: 0px;
                    width: calc(100vw - 100px);
                    height: 2px;
                    background-color: #4eb1dc;
                    content: '';
                }
                &.step-3{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
`;	

export default StepsStyle;