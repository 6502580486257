import React , { useState , useRef , useEffect } from "react";

import { useQueryParam, StringParam } from "use-query-params";

import { FaHome, FaQuestion , FaEnvelopeOpenText , FaDollarSign , FaStackExchange , FaCogs } from 'react-icons/fa';
import FormValuating from '../components/FormValuating/FormValuating';
import FormExchange from '../components/FormExchange/FormExchange';
import FormUtylize from '../components/FormUtylize/FormUtylize';
import UnderHeader from '../sections/UnderHeader/UnderHeader';
import Categories from '../sections/Categories/Categories';
import MenuMobile from '../sections/MenuMobile/MenuMobile';
import IndexStyle from './indexStyle';
import StylesGlobal from '../StylesGlobal';
import Header from '../sections/Header/Header';
import Steps from '../sections/Steps/Steps';
import Info from '../sections/Info/Info';
import Logos from '../sections/Logos/Logos';
import Icons from '../sections/Icons/Icons';
import Faq from '../sections/Faq/Faq';
import Opinions from '../sections/Opinions/Opinions';
import Map from '../sections/Map/Map';
import FormSell from '../components/FormSell/Form';
import NotificationSystem from 'react-notification-system';
import { reactLocalStorage } from 'reactjs-localstorage';
import { isEmpty, readCookie } from '../actions/functions';
import Login from '../sections/Login/Login';
import api from '../actions/api';
import Forget from '../sections/Forget/Forget';
import SeoMeta from "../components/SeoMeta/SeoMeta"
import Comment from '../components/Comment/Comment';
import Cookies from "../components/Cookies/Cookies";
import Messanger from '../components/Messanger/Messanger';

const IndexPage = () => {

    const [tkn, setTkn] = useQueryParam("token", StringParam);
    const refNotify = useRef();
    const refCategories = useRef();
    const refForms = useRef();
    const refFaq = useRef();
    const refContact = useRef();
    const refTop = useRef();

    const [mm,setMm] = useState(false);
    const [showForm,setShowForm] = useState(0);
    const [userData,setUserData] = useState(null);
    const [userToken,setUserToken] = useState('');
    const [readUserDataStorage,setReadUserDataStorage] = useState(0);
    const [showLogin,setShowLogin] = useState(false);
    const [showForget,setShowForget] = useState(false);
    const [dataComment,setDataComment] = useState(null);

    useEffect(() => {
        const tempUserData = reactLocalStorage.getObject('userData');
        if (!isEmpty(tempUserData)){
            setUserData(tempUserData);
        }
        const tempUserToken = reactLocalStorage.get('userToken');
        if (tempUserToken !== undefined){
            setUserToken(tempUserToken);
        }
    },[readUserDataStorage]);

    useEffect(() => {
        if (tkn!='' && tkn!==undefined){
            setShowForget(true);
        }
    },[]);

    const dataMenu = [
        { name: 'Strona główna', className: 'mainPage', icon: FaHome , slide: 'top' },
        { name: 'Kategoria tonerów', icon: FaCogs , slide: 'categories' },
        { name: 'Sprzedaj', icon: FaDollarSign , slide: 'forms' },
        { name: 'FAQ' , icon: FaQuestion , slide: 'faq' },
        { name: 'Kontakt' , icon: FaEnvelopeOpenText , slide: 'contact' },
        { name: 'Ekspresowa wycena' , className: 'express' , icon: FaStackExchange , express: true },
    ]

    const userLogout = () => {
        api('logout',userToken,{},r => {
            reactLocalStorage.remove('userToken');
            reactLocalStorage.remove('userData');
            setUserData(null);
            setUserToken('');
        });
    }

    const Notify = (title ,message, level= 'success') => {
        refNotify.current.addNotification({
            title,
            message,
            level,
            position: 'tc'
        });
    }

    const slideTo = slide => {
        if (slide === 'categories'){
            refCategories.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'forms'){
            refForms.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'faq'){
            refFaq.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'contact'){
            refContact.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'top'){
            refTop.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    } 

    // const clearCahce = () => {
    //     reactLocalStorage.remove("data_form_1");
    //     reactLocalStorage.remove("data_form_2");
    //     reactLocalStorage.remove("data_form_3");
    //     reactLocalStorage.remove("data_form_4");
    //     reactLocalStorage.remove("userData");
    //     reactLocalStorage.remove("userToken");
    // }

    return (
        <>
            <SeoMeta />
            <StylesGlobal />
            <NotificationSystem ref={refNotify} />

            <MenuMobile dataMenu={dataMenu} mm={mm} setMm={setMm} setShowForm={setShowForm} userData={userData} setShowLogin={setShowLogin} userLogout={userLogout} slideTo={slideTo} refTop={refTop} />
            <IndexStyle id="wrapper">

                <Header 
                    dataMenu={dataMenu} 
                    setMm={setMm} 
                    setShowForm={setShowForm} 
                    userData={userData} 
                    setShowLogin={setShowLogin} 
                    userLogout={userLogout} 
                    slideTo={slideTo} 
                    refTop={refTop} 
                    setShowForget={setShowForget}
                    userToken={userToken}
                    setTkn={setTkn}
                />
                <UnderHeader setShowForm={setShowForm} refForms={refForms} />
                <Steps setShowForm={setShowForm} />
                <Info />
                <Categories refCategories={refCategories} />
                <Logos />
                <Icons />
                <Faq refFaq={refFaq} />
                <Opinions />
                <Map refContact={refContact} />

            </IndexStyle>
            {showForm==1 &&
                <FormValuating setShowForm={setShowForm} showForm={showForm} Notify={Notify} userData={userData} userToken={userToken} setDataComment={setDataComment} />
            }
            {showForm==2 &&
                <FormSell setShowForm={setShowForm} showForm={showForm} Notify={Notify} userData={userData} userToken={userToken} setDataComment={setDataComment} />
            }
            {showForm==3 &&
                <FormExchange setShowForm={setShowForm} showForm={showForm} Notify={Notify} userData={userData} userToken={userToken} setDataComment={setDataComment} />
            }
            {showForm==4 &&
                <FormUtylize setShowForm={setShowForm} showForm={showForm} Notify={Notify} userData={userData} userToken={userToken} setDataComment={setDataComment} />
            }
            {showLogin &&
                <Login setShowLogin={setShowLogin} setReadUserDataStorage={setReadUserDataStorage} Notify={Notify} />
            }
            {showForget &&
                <Forget setShowForget={setShowForget} token={tkn} setReadUserDataStorage={setReadUserDataStorage} Notify={Notify} />
            }

            {dataComment!==null && <Comment data={dataComment} setData={setDataComment} />}
            <Cookies />
            <Messanger />
        </>
    )
}

export default IndexPage
