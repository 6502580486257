import styled from 'styled-components';
import { viewMobile } from '../../actions/variables';

const CommentStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,0.7);
    .overlay{
        width: 500px;
        min-height: 200px;
        background-color: #f7f7f7;
        overflow: hidden;
        header{
            color: grey;
            padding: 20px 20px;
            background-color: var(--color-base-dark);
            color: white;
            margin-bottom: 20px;
            border-bottom: 1px solid grey;
            position: relative;
            span{
                color: var(--color-lime);
                font-weight: 50
            }
        }
        .body{
            padding: 10px 30px;
            text-align: center;
            font-size: 16px;
            color: #565656;
            .buttonClose{
                padding: 20px;
                button{
                    outline: 0px;
                    border: 0px;
                    background-color: grey;
                    font-size: 20px;
                    color: white;
                    padding: 10px 20px;
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover{
                        background-color: black;
                    }
                }
            }
        }
    }
    @media (max-width: ${viewMobile}px){
        .overlay{
            width: 100%;
            height: 100%;
            .body .row.buttons button.close{
                display: block;
            }
        }
    }
`;	

export default CommentStyle;