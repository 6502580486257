import React , { useState, useEffect } from 'react';
import ForgetStyle from './ForgetStyle';
import { useClickOutside } from 'react-click-outside-hook';
import api from '../../actions/api';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FaTimes } from 'react-icons/fa';
import { isEmpty } from '../../actions/functions';

const Login = ({ setShowForget , Notify , token , setReadUserDataStorage }) => {

    const [password,setPassword] = useState('');
    const [password2,setPassword2] = useState('');
    const [loading,setLoading] = useState(false);

    const [errPassword,setErrPassword] = useState(false);
    const [errPassword2,setErrPassword2] = useState(false);

    const [refOverlay, hasClickedOutsideOverlay] = useClickOutside();

    useEffect(() => {
        if (hasClickedOutsideOverlay){
            setShowForget(false);
        }
    },[hasClickedOutsideOverlay]);

    const putLocalStorages = userData => {
        for(let i=1;i<=3;i++){
            const dataStorage = reactLocalStorage.getObject('data_form_'+i);
            if (!isEmpty(dataStorage)){
                dataStorage.name = userData.name;
                dataStorage.surname = userData.surname;
                dataStorage.city = userData.city;
                dataStorage.code = userData.code;
                dataStorage.street = userData.street;
                dataStorage.house = userData.street_number;
                dataStorage.country = userData.country;
                dataStorage.email = userData.email;
                dataStorage.phone = userData.phone;
                dataStorage.bank = userData.bank_account;
                dataStorage.firm = userData.firm_name;
                reactLocalStorage.setObject('data_form_'+i,dataStorage)
            }
        }
    }

    const handleButtonClose = ev => {
        ev.preventDefault();
        setShowForget(false);
    }

    const handleChangePassword = () => {
        setLoading(true);
        api('changePassword','',{ password, password2, token },r => {
            setLoading(false);
            if (r.result){

                reactLocalStorage.setObject('userData',r.data.userData);
                reactLocalStorage.set('userToken',r.data.token);
                putLocalStorages(r.data.userData);
                setReadUserDataStorage(v => v+1);

                setShowForget(false);
                Notify('Zmiana hasła',r.data.comm,'success');
            } else {
                Notify('Zmiana hasła',r.comm,'error');
                // setErrEmail(true);
            }
        });
    }

    return (
        <ForgetStyle>
            <div className={"overlay"+(loading ? ' loadingLayer' : '')} ref={refOverlay}>
                <header>Utwórz nowe hasło</header>
                <div className="body">

                    <div className={"row"+(errPassword ? ' error' : '')}>
                        <label>Podaj nowe hasło</label>
                        <input type="password" value={password} onChange={ev => setPassword(ev.target.value)} onFocus={() => setErrPassword(false)} />
                    </div>
                    <div className={"row"+(errPassword2 ? ' error' : '')}>
                        <label>Powtórz ponownie hasło</label>
                        <input type="password" value={password2} onChange={ev => setPassword2(ev.target.value)} onFocus={() => setErrPassword2(false)} />
                    </div>
                    <div className="row buttons">
                        <button onClick={handleChangePassword}>Zmień</button>
                        <button onClick={handleButtonClose} className="close"><FaTimes /> Zamknij</button>
                    </div>
                    
                </div>
            </div>
        </ForgetStyle>
    );
}
export default Login;