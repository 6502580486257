import styled from 'styled-components';
import { viewMax , viewMobile , viewHdSmall } from '../../actions/variables';

const CategoriesStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    .contentWidth{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        .category{
            display: flex;
            flex-direction: row;
            .main{
                flex: 1;
                padding: 40px;
                background: rgb(6,103,145);
                background: linear-gradient(153deg, rgba(6,103,145,1) 0%, rgba(6,103,145,1) 49%, rgba(2,56,94,1) 100%);
                color: white;
                label{
                    display: block;
                    font-size: 104px;
                    font-weight: 700;
                    border-bottom: 7px solid white;
                    margin: 0px;
                    padding: 0px;
                }
                p{
                    font-weight: 500;
                }
            }
            .image{
                flex: 1;
                padding: 20px;
                background-color: #f2f0f1;
                display: flex;
                align-items: center;
                padding: 0px 40px;
                img{
                    max-width: 100%;
                }
            }
        }
    }
    @media (max-width: ${viewMax}px){
        .contentWidth{
            .category{
                .main{
                    padding: 25px;
                }
                .image{
                    max-width: 45%;
                }
            }
        }
    }
    @media (max-width: ${viewHdSmall}px){
        .contentWidth{
            display: block;
            .category{
                margin-bottom: 5px;
            }
        }
    }
    @media (max-width: ${viewMobile}px){
        .contentWidth{
            .category{
                display: block;
                .main{
                    label{
                        font-size: 64px;
                        border-bottom: 3px solid white;
                    }
                }
                .image{
                    max-width: 100%;
                    text-align: center;
                    padding: 45px 25px;
                    display: block;
                    img{
                        max-width: 90%;
                    }
                }
            }
        }
    }
`;	

export default CategoriesStyle;