import React , { useState, useEffect } from 'react';
import StepsStyle from './StepsStyle';

const Steps = ({ maxSteps, current , classStyle = null, goStep, setCheckStep }) => {

    const [steps,setSteps] = useState([]);

    useEffect(() => {
        let temp = [];
        for(let i=1;i<=maxSteps;i++){
            temp = [...temp,...[1]]
        }
        setSteps(temp);
    },[maxSteps])

    const handleClick = (ev,index) => {
        ev.preventDefault();
        console.log('klik: ',index);
        if (index === current){
            setCheckStep(current);
        } else if (index+1<=current){
            goStep(index+1);
        }
    }

    return (
        <StepsStyle className={classStyle}>
            {steps.map((el,index) => (
                <div className={"step"+(index+1<=current ? ' checked' : '')+(index === current ? ' next' : '')} key={`step_${index}`} onClick={ev => handleClick(ev,index)}>
                    
                </div>
            ))}
        </StepsStyle>
    );
}
export default Steps;