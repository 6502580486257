import React , { useEffect } from 'react';

import { useClickOutside } from 'react-click-outside-hook';

import CommentStyle from './CommentStyle';

let timeout = null;

const Comment = ({ data, setData }) => {

    const [refOverlay, hasClickedOutsideOverlay] = useClickOutside();

    useEffect(() => {
        if (data?.autoclose){
            const time = data?.autocloseTime ? data.autocloseTime : 5000;
            timeout = setTimeout(() => {
                setData(null);
            },time);
        }
        return () => clearTimeout(timeout);
    },[data]);

    useEffect(() => {
        if (hasClickedOutsideOverlay){
            setData(null);
        }
    },[hasClickedOutsideOverlay]);

    const handleClose = () => {
        setData(null);
    }

    return (
        <CommentStyle>
            <div className={"overlay"} ref={refOverlay}>
                {(data?.header !== '' && data?.header !== undefined) && <header>{data.header}</header>}
                <div className="body">
                    {data?.content}
                    {data?.buttonClose && (
                        <div className="buttonClose">
                            <button onClick={handleClose}>Zamknij</button>
                        </div>                            
                    )}
                </div>
            </div>
        </CommentStyle>
    );
}
export default Comment;