import React from 'react';
import IconsStyle from './IconsStyle';
import imageBg from '../../assets/images/blue-bg.jpg';
import Svg from '../../components/Svgs/Svgs';

const Icons = () => {

    const data = [
        { icon: <Svg name="hand" />, content: 'Szybkie płatności' },
        { icon: <Svg name="money" />, content: 'Wysokie ceny skupu' },
        { icon: <Svg name="pack" />, content: 'Skupujemy również produkty w uszkodzonych opakowaniach lub bez opakowań' },
        { icon: <Svg name="toner" />, content: 'Skupujemy niemal wszystko' },
        { icon: <Svg name="hand" />, content: 'Stabilna firma z dużym doświadczeniem działająca od 2002r.' },
    ]

    return (
        <IconsStyle image={imageBg}>
            <div className="contentWidth">
                <h4>Dlaczego warto wybrać właśnie nas?</h4>
                <div className="icons">
                    {data.map((obj,index) => (
                        <div className="ico" key={`icons_${index}`}>
                            <div className="svg">{obj.icon}</div>
                            <h5>{obj.content}</h5>
                        </div>
                    ))}
                </div>
            </div>
        </IconsStyle>
    );
}
export default Icons;