import styled from 'styled-components';
import { viewMax ,viewSd , viewHdSmall } from '../../actions/variables';

const IconsStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 835px;
    background-image: url(${props => props.image});
    background-size: cover;
    .contentWidth{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        h4{
            /* flex: 1; */
            margin: 0px 0px 50px 0px;
            color: white;
            font-size: 34px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
        }
        .icons{
            /* flex: 1; */
            display: flex;
            flex-direction: row;
            .ico{
                flex: 1;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                h5{
                    max-width: 240px;
                    color: white;
                    font-weight: 500;
                    font-size: 18px;
                }
                .svg{
                    width: 220px;
                    height: 220px;
                    background-color: #e2e3e5;
                    padding: 40px;
                    border-radius: 130px;
                    text-align: center;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    @media (max-width: ${viewMax}px){
        .contentWidth{
            .icons{
                .ico{
                    h5{
                        font-size: 16px;
                        padding: 0px 10px;
                    }
                    .svg{
                        width: 170px;
                        height: 170px;
                    }
                }
            }
        }
    }
    @media (max-width: ${viewHdSmall}px){
        .contentWidth{
            .icons{
                flex-wrap: wrap;
                .ico{
                    padding: 0px 30px;
                }
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .contentWidth{
            display: block;
            padding: 30px 0px;
            h4{
                font-size: 26px;
                padding: 0px 25px;
            }
        }
    }
`;	

export default IconsStyle;