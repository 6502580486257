import styled from 'styled-components';
import { viewMobile } from '../../actions/variables';

const LoginStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,0.7);
    .overlay{
        width: 500px;
        min-height: 300px;
        background-color: #f7f7f7;
        overflow: hidden;
        header{
            color: grey;
            padding: 20px 20px;
            background-color: var(--color-base-dark);
            color: white;
            margin-bottom: 20px;
            border-bottom: 1px solid grey;
            position: relative;
            span{
                color: var(--color-lime);
                font-weight: 50
            }
        }
        .body{
            padding: 10px 30px;
            .content{
                margin-bottom: 15px;
                font-size: 12px;
                color: grey;
            }
            .row{
                margin-bottom: 20px;
                label{
                    display: block;
                    color: grey;
                    font-weight: 300;
                    margin-bottom: 10px;
                    transition: all 0.3s;
                }
                input , button{
                    display: block;
                    width: 100%;
                    border: 1px solid #dadada;
                    padding: 15px 20px;
                    color: grey;
                    background-color: #efefef;
                    outline: 0px;
                    color: black;
                    transition: all 0.3s;
                    &:focus{
                        border-color: grey;
                        background-color: white;
                    }
                }
                &.buttons{
                    display: flex;
                    flex-direction: row;
                    button{
                        display: inline-block;
                        max-width: 150px;
                        background-color: var(--color-base);
                        border: 1px solid var(--color-base-dark);
                        cursor: pointer;
                        color: white;
                        &:hover{
                            background-color: var(--color-base-dark);
                        }
                        &.close{
                            float: right;
                            background-color: red;
                            display: none;
                        }
                    }
                    .forget{
                        display: block;
                        margin: 30px 0px 0px 20px;
                        color: var(--color-base-dark);
                        &:hover{
                            color: black;
                        }
                    }
                }
                &.error{
                    label{
                        color: red;
                    }
                    input{
                        border-color: red;
                        background-color: #ffd7d7;
                    }
                }
            }
        }
    }
    @media (max-width: ${viewMobile}px){
        .overlay{
            width: 100%;
            height: 100%;
            .body .row.buttons button.close{
                display: block;
            }
        }
    }
`;	

export default LoginStyle;